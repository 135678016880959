import React, { useContext, useEffect, useState } from "react";
import "../assets/css/modal.css";
import Swal from "sweetalert2";
import { ReactComponent as CloseIcon } from "../assets/img/close2.svg";
import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";
import { ReactComponent as GradosIcon } from "../assets/img/iconos/acae_belt.svg";
import { ReactComponent as FukushidoinIcon } from "../assets/img/iconos/acae_t_fukushidoin.svg";
import { ReactComponent as ShidoinIcon } from "../assets/img/iconos/acae_t_shidoin.svg";
import { ReactComponent as ShihanIcon } from "../assets/img/iconos/acae_t_shihan.svg";

//Contexto
import ConexContext from "../context/conex/ConexContext";

const MisGradosMod = (props) => {
	const {
		loc,
		peticion,
		perfil_id,
		perfil_admin,
		perfil_adm_dojo,
		Cargando,
		perfil_dojo,
	} = useContext(ConexContext);
	const [Cinturones, setCinturones] = useState(null);
	const [Titulos, setTitulos] = useState(null);
	const [Grados, setGrados] = useState(null);
	const [CinturonesSelect, setCinturonesSelect] = useState([]);
	const [cinturonesIntermedios, setCinturonesIntermedios] = useState(false);
	const [Error, setError] = useState("");
	const [TitulosSelect, setTitulosSelect] = useState([]);
	const [TitulosSelectAnteriores, setTitulosSelectAnteriores] = useState([]);
	// Obtener la fecha actual en formato YYYY-MM-DD
	// const today = new Date().toISOString().split("T")[0];

	// Función para comparar si dos objetos son iguales
	const objetosIguales = (obj1, obj2) => {
		return (
			obj1.idcinturon === obj2.idcinturon &&
			obj1.fecha === obj2.fecha &&
			obj1.estado === obj2.estado &&
			obj1.nhoras === obj2.nhoras
		);
	};

	function obtenerEstado(valor) {
		if (valor < 0) {
			return loc("Pendiente");
		} else if (valor === 0) {
			return loc("Regulado");
		} else if (valor > 0) {
			return loc("Examinado");
		}
		return ""; // Por si acaso se pasa un valor que no cumpla las condiciones
	}

	// useEffect(() => {console.log(CinturonesSelect)}, [CinturonesSelect]);

	useEffect(() => {
		const cargarCinturones = async () => {
			try {
				const respuesta = await peticion(`/grados/cinturones`);
				if (respuesta?.estado === 0) {
					setCinturones(
						respuesta.res.map((obj) => ({
							...obj,
							fecha: "",
							estado: "",
						}))
					);
				} else {
					console.error(
						"Error al cargar los cinturones:",
						respuesta?.mensaje ?? "Error desconocido"
					);
				}
				const respuesta2 = await peticion(`/grados/titulos`);
				if (respuesta2?.estado === 0) {
					setTitulos(
						respuesta2.res.map((obj) => ({
							...obj,
							fecha: "",
							estado: "",
						}))
					);
				} else {
					console.error(
						"Error al cargar los títulos:",
						respuesta2?.mensaje ?? "Error desconocido"
					);
				}
				//grado del asociado
				peticion(
					"/grados/gradoactual?idAsoc=" + (props.perfil || perfil_id)
				).then((pet) => {
					if (pet.estado === 0) {
						setGrados(pet?.res ?? []);
						(pet?.res ?? []).forEach((obj) => {
							if (obj?.valor % 2 !== 0) {
								setCinturonesIntermedios(true);
							}
						});
						const filteredGrados = (pet?.res ?? []).map((obj) => ({
							idcinturon: obj.idcinturon, // Reemplaza con el nombre de la propiedad
							nhoras: obj.nhoras,
							fecha: obj.fecha, // Reemplaza con el nombre de la propiedad
							estado: obj.estado, // Reemplaza con el nombre de la propiedad
							valor: obj.valor
						}));
						setCinturonesSelect(filteredGrados);
					}
				});
				//titulo del asociado
				const respuesta3 = await peticion(
					`/grados/tituloactual?idAsoc=` + (props.perfil || perfil_id)
				);
				if (respuesta3?.estado === 0) {
					setTitulosSelect(respuesta3?.res ?? []);
					setTitulosSelectAnteriores(respuesta3?.res ?? []);
				} else {
					console.error(
						"Error al cargar el titulo del asociado:",
						respuesta3?.mensaje ?? "Error desconocido"
					);
				}
			} catch (error) {
				console.error("Error al cargar los cinturones:", error);
			}
		};

		if (props.show === 0) return;
		cargarCinturones();
	}, [peticion, props.show, perfil_id, props.perfil]);

	// guardar el registro
	async function guarda() {
		// Función para verificar si los objetos tienen el mismo idcinturon
		const objetosIgualesPorId = (obj1, obj2) =>
			obj1.idcinturon === obj2.idcinturon;

		// Obtener los objetos añadidos
		let objetosAñadidos = CinturonesSelect.filter(
			(objSelect) =>
				!Grados.some((objGrado) => objetosIgualesPorId(objSelect, objGrado))
		);
		// Obtener los objetos quitados
		let objetosQuitados = Grados.filter(
			(objGrado) =>
				!CinturonesSelect.some((objSelect) =>
					objetosIgualesPorId(objSelect, objGrado)
				)
		);
		// Obtener los objetos modificados
		let objetosModificados = CinturonesSelect.filter((objSelect) => {
			const objGrado = Grados.find((obj) =>
				objetosIgualesPorId(objSelect, obj)
			);
			return objGrado && !objetosIguales(objSelect, objGrado);
		});

		// requeridos
		const error = {};
		if (
			CinturonesSelect.length < 1 &&
			objetosAñadidos.length < 1 &&
			objetosQuitados.length < 1 &&
			objetosModificados.length < 1
		) {
			error.global = loc(
				"Debe haber al menos un cinturón seleccionado/eliminado/modificado"
			);
		}
		let erroresPorCinturon = {}; // Objeto para guardar errores por cinturón
		CinturonesSelect.forEach((el) => {
			let err = "";
			// Validación de campos obligatorios
			if (el.fecha === "" ) 
				err = loc("La fecha es obligatoria");
			if (el.nhoras === "") 
				err = loc("Las horas son obligatorias");
			
			// Validación de nhoras (número y mayor o igual que 0)
			if (isNaN(el.nhoras) || el.nhoras < 0) {
				err = loc(
					"El número de horas debe ser un número válido mayor o igual que 0"
				);
			}
			// Si hay un error, agregarlo al objeto de errores por cinturón
			if (err !== "") {
				erroresPorCinturon[el.idcinturon] = { error: err };
			}
		});

		// Verificar si hay errores globales o por cinturón
		if (
			Object.keys(error).length > 0 ||
			Object.keys(erroresPorCinturon).length > 0
		) {
			const nuevosCinturones = CinturonesSelect.map((cinturon) => {
				const errorCinturon = erroresPorCinturon[cinturon.idcinturon]; // Error específico por id
				if (errorCinturon) {
					return {
						...cinturon, // Mantener propiedades existentes
						...errorCinturon, // Combinar con el error específico
					};
				}
				return cinturon; // Si no hay error, devolver el objeto original
			});
			setCinturonesSelect(nuevosCinturones);
			if (error.global) {
				setError(error.global); // Manejar error global, si aplica
			}
			return;
		}
		// Verificar si hay errores por titulo
		let erroresPorTitulo = {}; // Objeto para guardar errores por titulo
		TitulosSelect.forEach((el) => {
			let err = "";
			// Validación de campos obligatorios
			if (el.fecha === "") {
				err = loc("La fecha es obligatoria");
			}
			// Si hay un error, agregarlo al objeto de errores por titulo
			if (err !== "") {
				erroresPorTitulo[el.idasoc_titulo] = { error: err };
			}
		});
		if (Object.keys(erroresPorTitulo).length > 0) {
			const nuevosTitulos = TitulosSelect.map((titulo) => {
				const errorTitulo = erroresPorTitulo[titulo.idasoc_titulo]; // Error específico por id
				if (errorTitulo) {
					return {
						...titulo, // Mantener propiedades existentes
						...errorTitulo, // Combinar con el error específico
					};
				}
				return titulo; // Si no hay error, devolver el objeto original
			});
			setTitulosSelect(nuevosTitulos);
			return;
		}
		// Función para verificar si los objetos tienen el mismo idcinturon
		const TitulosIgualesPorId = (obj1, obj2) => obj1.cftitulo === obj2.cftitulo;

		// Obtener los objetos añadidos
		let TitulosAñadidos = TitulosSelect.filter(
			(objSelect) =>
				!TitulosSelectAnteriores.some((objGrado) =>
					TitulosIgualesPorId(objSelect, objGrado)
				)
		);
		// Obtener los objetos quitados
		let TitulosQuitados = TitulosSelectAnteriores.filter(
			(objGrado) =>
				!TitulosSelect.some((objSelect) =>
					TitulosIgualesPorId(objSelect, objGrado)
				)
		);
		// Obtener los objetos modificados
		let TitulosModificados = TitulosSelect.filter((objSelect) => {
			const objGrado = TitulosSelectAnteriores.find((obj) =>
				TitulosIgualesPorId(objSelect, obj)
			);
			return objGrado && !objetosIguales(objSelect, objGrado);
		});
		let pet;
		if (objetosAñadidos.length > 0) {
			// Agregar una nueva propiedad a cada objeto en el array
			const objetosConNuevaPropiedad = objetosAñadidos.map((obj) => ({
				...obj, // Mantener todas las propiedades del objeto original
				cfasociado: props.perfil || perfil_id, // Aquí agregas la nueva propiedad
			}));
			pet = await peticion("/grados/ins", {
				method: "POST",
				json: {
					cinturones: objetosConNuevaPropiedad,
				},
			});
			if (pet.estado)
				//algún error
				return;
		}
		if (objetosQuitados.length > 0) {
			pet = await peticion("/grados/delGrado", {
				method: "POST",
				json: {
					cinturones: objetosQuitados,
				},
			});
			if (pet.estado)
				//algún error
				return;
		}
		if (objetosModificados.length > 0) {
			// Agregar una nueva propiedad a cada objeto en el array
			const objetosConNuevaPropiedad = objetosModificados.map((obj) => ({
				...obj, // Mantener todas las propiedades del objeto original
				idGrado: Grados.find((item) => item.idcinturon === obj.idcinturon)
					.idgrado, // Aquí agregas la nueva propiedad
				cfasociado: props.perfil || perfil_id,
			}));
			pet = await peticion("/grados/mod", {
				method: "POST",
				json: {
					cinturones: objetosConNuevaPropiedad,
				},
			});
			if (pet.estado)
				//algún error
				return;
		}
		if (TitulosAñadidos.length > 0) {
			// Agregar una nueva propiedad a cada objeto en el array
			const objetosConNuevaPropiedad = TitulosAñadidos.map((obj) => ({
				...obj, // Mantener todas las propiedades del objeto original
				cfasociado: props.perfil || perfil_id, // Aquí agregas la nueva propiedad
			}));
			pet = await peticion("/grados/tituloins", {
				method: "POST",
				json: {
					titulos: objetosConNuevaPropiedad,
				},
			});
			if (pet.estado)
				//algún error
				return;
		}
		if (TitulosQuitados.length > 0) {
			pet = await peticion("/grados/titulodel", {
				method: "POST",
				json: {
					titulos: TitulosQuitados,
				},
			});
			if (pet.estado)
				//algún error
				return;
		}
		if (TitulosModificados.length > 0) {
			// Agregar una nueva propiedad a cada objeto en el array
			const objetosConNuevaPropiedad = TitulosModificados.map((obj) => ({
				...obj, // Mantener todas las propiedades del objeto original
				idasoc_titulo: Titulos.find((item) => item.cftitulo === obj.cftitulo)
					.idasoc_titulo, // Aquí agregas la nueva propiedad
				cfasociado: props.perfil || perfil_id,
			}));
			pet = await peticion("/grados/titulomod", {
				method: "POST",
				json: {
					titulos: objetosConNuevaPropiedad,
				},
			});
			if (pet.estado)
				//algún error
				return;
		}
		// SI TODO BIEN RECARGAR VALORES DE GRADOS Y CERRAR EL MODAL
		//grado del asociado
		peticion("/grados/gradoactual?idAsoc=" + (props.perfil || perfil_id)).then(
			(pet) => {
				if (pet.estado === 0) props.setGrados(pet?.res ?? []);
				cierra();
			}
		);
	}

	//cierra el modal
	function cierra() {
		props.onHide();
	}

	// añade kyus
	function kyus(elem) {
		const index = Cinturones.findIndex(
			(c) => c.idcinturon === elem.idcinturon
		);
		if (
			CinturonesSelect.some(
				(c) => c.idcinturon === elem.idcinturon
			)
		) {
			const cinturon_index = CinturonesSelect.findIndex(
				(c) => c.idcinturon === elem.idcinturon
			);
			if (
				!perfil_admin &&
				!perfil_adm_dojo &&
				CinturonesSelect[cinturon_index].estado >= 0 &&
				CinturonesSelect[cinturon_index].estado !== ""
			) {
				Swal.fire({
					title: "Permiso Denegado",
					text: "No puedes quitar este grado",
					showConfirmButton: true,
					icon: "info",
					heightAuto: false,
				});
				return;
			}
			if (
				perfil_adm_dojo &&
				perfil_dojo !== props.asociadoData?.cfDojo
			) {
				Swal.fire({
					title: "Permiso Denegado",
					text: "No puedes quitar este grado, no es de tu Dojo",
					showConfirmButton: true,
					icon: "info",
					heightAuto: false,
				});
				return;
			}
			// Eliminar el elemento
			const nuevosGrados = CinturonesSelect.filter(
				(c) =>
					c.idcinturon !== Cinturones[index].idcinturon
			);
			setCinturonesSelect(nuevosGrados);
		} else {
			// Agregar el elemento si cumplen la condición
			setError("");
			const nuevosGrados = [
				...CinturonesSelect,
				...Cinturones.filter(
					(c, i) =>
						c.idcinturon ===
							Cinturones[index].idcinturon && // Solo agregar el elemento en el índice dado
						(cinturonesIntermedios ||
							c.valor % 2 === 0) &&
						!CinturonesSelect.some(
							(selected) =>
								selected.idcinturon === c.idcinturon
						) // Evitar duplicados
				).map((c) => ({
					nhoras: c.nhoras || elem.nhoras || "0",
					idcinturon: c.idcinturon,
					fecha: c.fecha || elem.fecha,
					estado: "",
					valor: c.valor || elem.valor,
				})),
			];
			setCinturonesSelect(nuevosGrados);
		}
	}

	// añade danes
	function danes(elem){
		const index = Cinturones.findIndex(
			(c) => c.idcinturon === elem.idcinturon
		);
		if (
			CinturonesSelect.some(
				(c) => c.idcinturon === elem.idcinturon
			)
		) {
			const cinturon_index = CinturonesSelect.findIndex(
				(c) => c.idcinturon === elem.idcinturon
			);
			if (
				!perfil_admin &&
				CinturonesSelect[cinturon_index].estado >= 0 &&
				CinturonesSelect[cinturon_index].estado !== ""
			) {
				Swal.fire({
					title: "Permiso Denegado",
					text: "No puedes quitar este grado",
					showConfirmButton: true,
					icon: "info",
					heightAuto: false,
				});
				return;
			}
			// Eliminar el elemento
			const nuevosGrados = CinturonesSelect.filter(
				(c) =>
					c.idcinturon !== Cinturones[index].idcinturon
			);
			setCinturonesSelect(nuevosGrados);
		} else {
			// Agregar el elemento si cumplen la condición
			setError("");
			const nuevosGrados = [
				...CinturonesSelect,
				...Cinturones.filter(
					(c, i) =>
						c.idcinturon ===
							Cinturones[index].idcinturon && // Solo agregar el elemento en el índice dado
						(cinturonesIntermedios ||
							c.valor % 2 === 0) &&
						!CinturonesSelect.some(
							(selected) =>
								selected.idcinturon === c.idcinturon
						) // Evitar duplicados
				).map((c) => ({
					nhoras: c.nhoras || elem.nhoras || "0",
					idcinturon: c.idcinturon,
					fecha: c.fecha || elem.fecha,
					estado: "",
					valor: c.valor || elem.valor,
				})),
			];
			setCinturonesSelect(nuevosGrados);
		}
	}

	// añade titulos
	function titulos(elem){
		const index = Titulos.findIndex(
			(c) => c.cftitulo === elem.cftitulo
		);
		if (
			TitulosSelect.some(
				(c) => c.cftitulo === elem.cftitulo
			)
		) {
			const titulo_index = TitulosSelect.findIndex(
				(c) => c.cftitulo === elem.cftitulo
			);
			if (
				!perfil_admin &&
				TitulosSelect[titulo_index].estado >= 0 &&
				TitulosSelect[titulo_index].estado !== ""
			) {
				Swal.fire({
					title: "Permiso Denegado",
					text: "No puedes quitar este titulo",
					showConfirmButton: true,
					icon: "info",
					heightAuto: false,
				});
				return;
			}
			// Eliminar el elemento
			const nuevosTitulos = TitulosSelect.filter(
				(c) => c.cftitulo !== Titulos[index].cftitulo
			);
			setTitulosSelect(nuevosTitulos);
		} else {
			// Agregar el elemento si cumplen la condición
			const nuevosTitulos = [
				...TitulosSelect,
				...Titulos.filter(
					(c, i) =>
						c.cftitulo === Titulos[index].cftitulo && // Solo agregar el elemento en el índice dado
						!TitulosSelect.some(
							(selected) =>
								selected.cftitulo === c.cftitulo
						) // Evitar duplicados
				).map((c) => ({
					cftitulo: c.cftitulo,
					fecha: c.fecha || elem.fecha,
					estado: "",
					valor: c.valor || elem.valor,
				})),
			];
			setTitulosSelect(nuevosTitulos);
		}
	}

	if (props.show === 0) return "";
	else
		return (
			<main className="modal-wrapper">
				<div className="modal-container backdrop content-wrapper grados">
					<div className="modal-header">
						<div className="flex-wrapper">
							<GradosIcon className="section-icon" />
							<h3>{loc("Nuevo grado")}</h3>
						</div>
						<CloseIcon
							onClick={cierra}
							className="modal_close-icon"
							alt="Cerrar ventana"
							title="(ESC)"
						/>
					</div>
					{Cargando && Grados && CinturonesSelect && Cinturones ? (
						<div className="backdrop precarga">
							<div className="loader-wrapper">
								<div className="circle-wrapper">
									<div className="circle-1" />
									<div className="circle-2" />
									<div className="circle-3" />
									<div className="circle-4" />
									<div className="circle-5" />
								</div>
								<AcaeIcon />
							</div>
							<span>{loc("Cargando...")}</span>
						</div>
					) : (
						<>
							<div className="modal-content-scroll form-container crear-curso">
								<div className="modal-content-outer-wrapper flex-column">
									<div className="grados-asociado-container">
										<div className="flex-row-item gap-small">
											<div className="user-avatar">
												<img
													src={
														"/avatars/" +
														(props.asociadoData?.avatar ?? "default.jpg")
													}
													alt="Avatar"
													className="avatar borde-grado--azul"
												/>
											</div>
											<div className="flex-row-item gap-small full-width-grow space-between">
												<div>
													<div className="flex-row-item gap-small">
														<h4>{props.asociadoData.nombreCompleto}</h4>
														<h4 className="numacae">
															{props.asociadoData.numAcae}
														</h4>
													</div>
													<div className="flex-row-item gap-small">
														<div className="flex-row-item gap-small text-italic cinturon-label">
															{
																Cinturones?.[
																	Cinturones?.findIndex(
																		(cinturon) =>
																			cinturon.idcinturon ===
																			CinturonesSelect.filter(
																				(el) => parseInt(el.estado) >= 0
																			)
																				.sort(
																					(a, b) => a.idcinturon - b.idcinturon
																				)
																				.slice(0, 1)[0]?.idcinturon
																	)
																]?.descripcion
															}
														</div>
														<div
															className="grado-color"
															style={{
																background: (() => {
																	const cinturonActualIndex =
																		Cinturones?.findIndex(
																			(cinturon) =>
																				cinturon.idcinturon ===
																				CinturonesSelect.filter(
																					(el) => parseInt(el.estado) >= 0
																				)
																					.sort(
																						(a, b) =>
																							a.idcinturon - b.idcinturon
																					)
																					.slice(0, 1)[0]?.idcinturon
																		);
																	const cinturonActual =
																		Cinturones?.[cinturonActualIndex];

																	const colorAnterior =
																		Cinturones?.[cinturonActualIndex - 1]
																			?.color_primario || "transparent";
																	const colorSiguiente =
																		Cinturones?.[cinturonActualIndex + 1]
																			?.color_primario || "transparent";

																	return cinturonActual?.valor % 10 !== 0
																		? `linear-gradient(90deg, ${colorAnterior} 50%, ${colorSiguiente} 50%)`
																		: cinturonActual?.color_primario ||
																				"transparent";
																})(),
															}}
														/>
													</div>
												</div>
												<div
													className={`cinturones-intermedios-toggle flex-row-item gap-small ${
														cinturonesIntermedios ? "selected" : ""
													}`}
													onClick={(e) =>
														setCinturonesIntermedios(!cinturonesIntermedios)
													}>
													<div className="checkbox" />
													<div>
														{cinturonesIntermedios
															? loc("Ocultar cinturones intermedios")
															: loc("Mostrar cinturones intermedios")}
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="horizontal-divider no-margin" />

									<div className="grid card gap-small">
										{Cinturones?.filter(
											(elem) =>
												elem?.valor < 0 &&
												(cinturonesIntermedios || elem?.valor % 2 === 0)
										).map((elem, i) => (
											<div
												className={`flex-column gap-small grados-listing ${
													CinturonesSelect?.some(
														(c) => c.idcinturon === elem.idcinturon
													)
														? "selected"
														: ""
												}`}
												key={i}
												onClick={() => kyus(elem)}>
												<div className="flex-row-item gap-small space-between">
													<div className="flex-row-item gap-small">
														<div
															className="grado-color"
															style={{
																background:
																	elem?.valor % 10 !== 0
																		? `linear-gradient(90deg, ${
																				Cinturones[i - 1]?.color_primario
																		  } 50%, ${
																				Cinturones[i + 1]?.color_primario
																		  } 50%)`
																		: elem?.color_primario,
															}}
														/>
														<div className="cinturon-label">
															{elem?.descripcion}
														</div>
													</div>
													{CinturonesSelect?.some(
														(c) => c.idcinturon === elem.idcinturon
													) &&
														(elem.estado !== "" ||
															CinturonesSelect.find(
																(item) => item.idcinturon === elem.idcinturon
															).estado !== "") && (
															<div
																className="grado-estado-validacion"
																style={{ cursor: "pointer" }}
																onClick={(e) => {
																	e.stopPropagation();
																	if (
																		CinturonesSelect.find(
																			(item) =>
																				item.idcinturon === elem.idcinturon
																		)
																	) {
																		if (
																			CinturonesSelect.find(
																				(item) =>
																					item.idcinturon === elem.idcinturon
																			).valor > 0
																		) {
																			console.log("IR AL MODAL DE EXAMEN");
																		}
																	}
																}}
																title={
																	Grados.find(
																		(item) =>
																			item.idcinturon === elem.idcinturon
																	)
																		? Grados.find(
																				(item) =>
																					item.idcinturon === elem.idcinturon
																		  ).estado >= 0
																			? "Regulado por " +
																			  Grados.find(
																					(item) =>
																						item.idcinturon === elem.idcinturon
																			  ).nombre_validador +
																			  " " +
																			  Grados.find(
																					(item) =>
																						item.idcinturon === elem.idcinturon
																			  ).apellido_validador
																			: ""
																		: ""
																}>
																<div
																	className={
																		(CinturonesSelect.find((item) => item.idcinturon === elem.idcinturon)
																			? CinturonesSelect.find((item) => item.idcinturon === elem.idcinturon).estado
																			: elem.estado) >= 0
																			? "indicador-verde"
																			: "indicador-naranja"
																	}
																></div>
																{CinturonesSelect.find((item) => item.idcinturon === elem.idcinturon)
																	? obtenerEstado(
																		CinturonesSelect.find((item) => item.idcinturon === elem.idcinturon)
																			.estado
																	)
																	: obtenerEstado(elem.estado)}
															</div>
														)}
												</div>
												<div className="flex-row-item gap-small space-between">
													<input
														className="grados-fecha"
														type="date"
														style={{ cursor: "pointer" }}
														onClick={(e) => e.stopPropagation()}
														disabled={
															CinturonesSelect.find(
																(item) => item.idcinturon === elem.idcinturon
															)
																? CinturonesSelect.find(
																		(item) =>
																			item.idcinturon === elem.idcinturon
																  ).estado >= 0 &&
																  CinturonesSelect.find(
																		(item) =>
																			item.idcinturon === elem.idcinturon
																  ).estado !== ""
																: elem.estado !== ""
														}
														value={
															CinturonesSelect.find(
																(item) => item.idcinturon === elem.idcinturon
															)?.fecha ||
															elem.fecha ||
															""
														}
														// min={CinturonesSelect.filter((item) => item.valor < elem.valor).sort((a, b) =>new Date(b.fecha) - new Date(a.fecha)).slice(0,1)[0]?.fecha}
														// max={CinturonesSelect.filter((item) => item.valor > elem.valor).sort((a, b) =>new Date(a.fecha) - new Date(b.fecha)).slice(0,1)[0]?.fecha}
														onChange={(self) => {
															setError("");
															if (
																CinturonesSelect.find(
																	(item) => item.idcinturon === elem.idcinturon
																)
															) {
																const cinturon_index =
																	CinturonesSelect.findIndex(
																		(c) => c.idcinturon === elem.idcinturon
																	);
																if (
																	!perfil_admin &&
																	!perfil_adm_dojo &&
																	CinturonesSelect[cinturon_index].estado >=
																		0 &&
																	CinturonesSelect[cinturon_index].estado !== ""
																) {
																	Swal.fire({
																		title: "Permiso Denegado",
																		text: "No puedes modificar este grado",
																		showConfirmButton: true,
																		icon: "info",
																		heightAuto: false,
																	});
																	return;
																}
																if (
																	perfil_adm_dojo &&
																	perfil_dojo !== props.asociadoData?.cfDojo
																) {
																	Swal.fire({
																		title: "Permiso Denegado",
																		text: "No puedes modificar este grado, no es de tu Dojo",
																		showConfirmButton: true,
																		icon: "info",
																		heightAuto: false,
																	});
																	return;
																}
																const nuevosCinturones = CinturonesSelect.map(
																	(item) => {
																		if (item.idcinturon === elem.idcinturon) {
																			return {
																				...item,
																				fecha: self.target.value,
																				error: "",
																			}; // Actualizamos la fecha
																		}
																		return item; // Devolvemos el objeto sin cambios si no coincide
																	}
																);
																// Actualizamos el estado con la nueva versión del array
																setCinturonesSelect(nuevosCinturones);
															} else {
																const index = Cinturones.findIndex(
																	(c) => c.idcinturon === elem.idcinturon
																);
																const nuevosGrados = [
																	...CinturonesSelect,
																	...Cinturones.filter(
																		(c, i) =>
																			c.idcinturon ===
																				Cinturones[index].idcinturon && // Solo agregar el elemento en el índice dado
																			(cinturonesIntermedios ||
																				c.valor % 2 === 0) &&
																			!CinturonesSelect.some(
																				(selected) =>
																					selected.idcinturon === c.idcinturon
																			) // Evitar duplicados
																	).map((c) => ({
																		nhoras: c.nhoras || elem.nhoras || "0",
																		idcinturon: c.idcinturon,
																		fecha: self.target.value,
																		estado: "",
																		valor: c.valor || elem.valor
																	})),
																];
																setCinturonesSelect(nuevosGrados);
															}
														}}
													/>
													<input
														className="grados-nhoras"
														type="number"
														onClick={(e) => e.stopPropagation()}
														placeholder={loc("Horas")}
														disabled={
															CinturonesSelect.find(
																(item) => item.idcinturon === elem.idcinturon
															)
																? CinturonesSelect.find(
																		(item) =>
																			item.idcinturon === elem.idcinturon
																  ).estado >= 0 &&
																  CinturonesSelect.find(
																		(item) =>
																			item.idcinturon === elem.idcinturon
																  ).estado !== ""
																: elem.estado !== ""
														}
														value={
															CinturonesSelect.find(
																(item) => item.idcinturon === elem.idcinturon
															)?.nhoras?.toString() ||
															elem.nhoras?.toString() ||
															""
														}
														onChange={(self) => {
															setError("");
															if (
																CinturonesSelect.find(
																	(item) => item.idcinturon === elem.idcinturon
																)
															) {
																const cinturon_index =
																	CinturonesSelect.findIndex(
																		(c) => c.idcinturon === elem.idcinturon
																	);
																if (
																	!perfil_admin &&
																	!perfil_adm_dojo &&
																	CinturonesSelect[cinturon_index].estado >=
																		0 &&
																	CinturonesSelect[cinturon_index].estado !== ""
																) {
																	Swal.fire({
																		title: "Permiso Denegado",
																		text: "No puedes modificar este grado",
																		showConfirmButton: true,
																		icon: "info",
																		heightAuto: false,
																	});
																	return;
																}
																if (
																	perfil_adm_dojo &&
																	perfil_dojo !== props.asociadoData?.cfDojo
																) {
																	Swal.fire({
																		title: "Permiso Denegado",
																		text: "No puedes modificar este grado, no es de tu Dojo",
																		showConfirmButton: true,
																		icon: "info",
																		heightAuto: false,
																	});
																	return;
																}
																const nuevosCinturones = CinturonesSelect.map(
																	(item) => {
																		if (item.idcinturon === elem.idcinturon) {
																			return {
																				...item,
																				nhoras: self.target.value,
																				error: "",
																			}; // Actualizamos la fecha
																		}
																		return item; // Devolvemos el objeto sin cambios si no coincide
																	}
																);
																// Actualizamos el estado con la nueva versión del array
																setCinturonesSelect(nuevosCinturones);
															} else {
																const updatedCinturones = Cinturones.map(
																	(item) => {
																		if (item.idcinturon === elem.idcinturon) {
																			return {
																				...item,
																				nhoras: self.target.value,
																				error: "",
																			}; // Crear un nuevo objeto con la fecha actualizada
																		}
																		return item; // Devolver el objeto sin cambios si no cumple la condición
																	}
																);
																setCinturones(updatedCinturones); // Actualizar el estado con la nueva copia del array
															}
														}}
													/>
												</div>
												<div className="flex-row-item gap-small space-between">
													<p className="error grados-error">
														{
															CinturonesSelect.find(
																(item) => item.idcinturon === elem.idcinturon
															)?.error
														}
													</p>
													<div className="checkbox" />
												</div>
											</div>
										))}
									</div>

									<div className="horizontal-divider no-margin" />

									<div className="grid card gap-small">
										{Cinturones?.filter((elem) => elem?.valor >= 0).map(
											(elem, i) => (
												<div
													className={`flex-column gap-small grados-listing dan ${
														CinturonesSelect?.some(
															(c) => c.idcinturon === elem.idcinturon
														)
															? "selected"
															: ""
													}`}
													key={i}
													onClick={() => {danes(elem)}}>
													<div className="flex-row-item gap-small space-between">
														<div className="flex-row-item gap-small">
															<div
																className="grado-color"
																style={{
																	background:
																		elem?.valor % 10 !== 0
																			? `linear-gradient(90deg, ${
																					Cinturones[i - 1]?.color_primario
																			  } 50%, ${
																					Cinturones[i + 1]?.color_primario
																			  } 50%)`
																			: elem?.color_primario,
																}}
															/>
															<div className="cinturon-label">
																{elem?.descripcion}
															</div>
														</div>
														{CinturonesSelect?.some(
															(c) => c.idcinturon === elem.idcinturon
														) &&
															(elem.estado !== "" ||
																CinturonesSelect.find(
																	(item) => item.idcinturon === elem.idcinturon
																).estado !== "") && (
																<div
																	className="grado-estado-validacion"
																	style={{ cursor: "pointer" }}
																	onClick={(e) => {
																		e.stopPropagation();
																		if (
																			CinturonesSelect.find(
																				(item) =>
																					item.idcinturon === elem.idcinturon
																			)
																		) {
																			if (
																				CinturonesSelect.find(
																					(item) =>
																						item.idcinturon === elem.idcinturon
																				).valor > 0
																			) {
																				console.log("IR AL MODAL DE EXAMEN");
																			}
																		}
																	}}
																	title={
																		Grados.find(
																			(item) =>
																				item.idcinturon === elem.idcinturon
																		)
																			? Grados.find(
																					(item) =>
																						item.idcinturon === elem.idcinturon
																			  ).estado >= 0
																				? "Regulado por " +
																				  Grados.find(
																						(item) =>
																							item.idcinturon ===
																							elem.idcinturon
																				  ).nombre_validador +
																				  " " +
																				  Grados.find(
																						(item) =>
																							item.idcinturon ===
																							elem.idcinturon
																				  ).apellido_validador
																				: ""
																			: ""
																	}>
																	<div
																		className={
																			(CinturonesSelect.find((item) => item.idcinturon === elem.idcinturon)
																				? CinturonesSelect.find((item) => item.idcinturon === elem.idcinturon).estado
																				: elem.estado) >= 0
																				? "indicador-verde"
																				: "indicador-naranja"
																		}
																	></div>
																	{CinturonesSelect.find((item) => item.idcinturon === elem.idcinturon)
																		? obtenerEstado(
																			CinturonesSelect.find((item) => item.idcinturon === elem.idcinturon)
																				.estado
																		)
																		: obtenerEstado(elem.estado)}
																</div>
															)}
													</div>
													<div className="flex-row-item gap-small space-between">
														<input
															className="grados-fecha"
															type="date"
															style={{ cursor: "pointer" }}
															onClick={(e) => e.stopPropagation()}
															disabled={
																CinturonesSelect.find(
																	(item) => item.idcinturon === elem.idcinturon
																)
																	? CinturonesSelect.find(
																			(item) =>
																				item.idcinturon === elem.idcinturon
																	  ).estado >= 0 &&
																	  CinturonesSelect.find(
																			(item) =>
																				item.idcinturon === elem.idcinturon
																	  ).estado !== ""
																	: elem.estado !== ""
															}
															value={
																CinturonesSelect.find(
																	(item) => item.idcinturon === elem.idcinturon
																)?.fecha ||
																elem.fecha ||
																""
															}
															// min={CinturonesSelect.filter((item) => item.valor < elem.valor).sort((a, b) =>new Date(b.fecha) - new Date(a.fecha)).slice(0,1)[0]?.fecha}
															// max={CinturonesSelect.filter((item) => item.valor > elem.valor).sort((a, b) =>new Date(a.fecha) - new Date(b.fecha)).slice(0,1)[0]?.fecha}
															onChange={(self) => {
																setError("");
																if (
																	CinturonesSelect.find(
																		(item) =>
																			item.idcinturon === elem.idcinturon
																	)
																) {
																	const cinturon_index =
																		CinturonesSelect.findIndex(
																			(c) => c.idcinturon === elem.idcinturon
																		);
																	if (
																		!perfil_admin &&
																		!perfil_adm_dojo &&
																		CinturonesSelect[cinturon_index].estado >=
																			0 &&
																		CinturonesSelect[cinturon_index].estado !==
																			""
																	) {
																		Swal.fire({
																			title: "Permiso Denegado",
																			text: "No puedes modificar este grado",
																			showConfirmButton: true,
																			icon: "info",
																			heightAuto: false,
																		});
																		return;
																	}
																	if (
																		perfil_adm_dojo &&
																		perfil_dojo !== props.asociadoData?.cfDojo
																	) {
																		Swal.fire({
																			title: "Permiso Denegado",
																			text: "No puedes modificar este grado, no es de tu Dojo",
																			showConfirmButton: true,
																			icon: "info",
																			heightAuto: false,
																		});
																		return;
																	}
																	const nuevosCinturones = CinturonesSelect.map(
																		(item) => {
																			if (item.idcinturon === elem.idcinturon) {
																				return {
																					...item,
																					fecha: self.target.value,
																					error: "",
																				}; // Actualizamos la fecha
																			}
																			return item; // Devolvemos el objeto sin cambios si no coincide
																		}
																	);
																	// Actualizamos el estado con la nueva versión del array
																	setCinturonesSelect(nuevosCinturones);
																} else {
																	const index = Cinturones.findIndex(
																		(c) => c.idcinturon === elem.idcinturon
																	);
																	const nuevosGrados = [
																		...CinturonesSelect,
																		...Cinturones.filter(
																			(c, i) =>
																				c.idcinturon ===
																					Cinturones[index].idcinturon && // Solo agregar el elemento en el índice dado
																				(cinturonesIntermedios ||
																					c.valor % 2 === 0) &&
																				!CinturonesSelect.some(
																					(selected) =>
																						selected.idcinturon === c.idcinturon
																				) // Evitar duplicados
																		).map((c) => ({
																			nhoras: c.nhoras || elem.nhoras || "0",
																			idcinturon: c.idcinturon,
																			fecha: self.target.value,
																			estado: "",
																			valor: c.valor || elem.valor
																		})),
																	];
																	setCinturonesSelect(nuevosGrados);
																}
															}}
														/>
														<input
															className="grados-nhoras"
															type="number"
															onClick={(e) => e.stopPropagation()}
															placeholder={loc("Horas")}
															disabled={
																CinturonesSelect.find(
																	(item) => item.idcinturon === elem.idcinturon
																)
																	? CinturonesSelect.find(
																			(item) =>
																				item.idcinturon === elem.idcinturon
																	  ).estado >= 0 &&
																	  CinturonesSelect.find(
																			(item) =>
																				item.idcinturon === elem.idcinturon
																	  ).estado !== ""
																	: elem.estado !== ""
															}
															value={
																CinturonesSelect.find(
																	(item) => item.idcinturon === elem.idcinturon
																)?.nhoras?.toString() ||
																elem.nhoras?.toString() ||
																""
															}
															onChange={(self) => {
																setError("");
																if (
																	CinturonesSelect.find(
																		(item) =>
																			item.idcinturon === elem.idcinturon
																	)
																) {
																	const cinturon_index =
																		CinturonesSelect.findIndex(
																			(c) => c.idcinturon === elem.idcinturon
																		);
																	if (
																		!perfil_admin &&
																		!perfil_adm_dojo &&
																		CinturonesSelect[cinturon_index].estado >=
																			0 &&
																		CinturonesSelect[cinturon_index].estado !==
																			""
																	) {
																		Swal.fire({
																			title: "Permiso Denegado",
																			text: "No puedes modificar este grado",
																			showConfirmButton: true,
																			icon: "info",
																			heightAuto: false,
																		});
																		return;
																	}
																	if (
																		perfil_adm_dojo &&
																		perfil_dojo !== props.asociadoData?.cfDojo
																	) {
																		Swal.fire({
																			title: "Permiso Denegado",
																			text: "No puedes modificar este grado, no es de tu Dojo",
																			showConfirmButton: true,
																			icon: "info",
																			heightAuto: false,
																		});
																		return;
																	}
																	const nuevosCinturones = CinturonesSelect.map(
																		(item) => {
																			if (item.idcinturon === elem.idcinturon) {
																				return {
																					...item,
																					nhoras: self.target.value,
																					error: "",
																				}; // Actualizamos la fecha
																			}
																			return item; // Devolvemos el objeto sin cambios si no coincide
																		}
																	);
																	// Actualizamos el estado con la nueva versión del array
																	setCinturonesSelect(nuevosCinturones);
																} else {
																	const updatedCinturones = Cinturones.map(
																		(item) => {
																			if (item.idcinturon === elem.idcinturon) {
																				return {
																					...item,
																					nhoras: self.target.value,
																					error: "",
																				}; // Crear un nuevo objeto con la fecha actualizada
																			}
																			return item; // Devolver el objeto sin cambios si no cumple la condición
																		}
																	);
																	setCinturones(updatedCinturones); // Actualizar el estado con la nueva copia del array
																}
															}}
														/>
													</div>
													<div className="flex-row-item space-between">
														<p className="error grados-error">
															{
																CinturonesSelect.find(
																	(item) => item.idcinturon === elem.idcinturon
																)?.error
															}
														</p>
														<div className="checkbox" />
													</div>
												</div>
											)
										)}
									</div>

									<div className="horizontal-divider no-margin" />

									<div className="grid card gap-small">
										{Titulos?.filter((elem) => elem?.valor >= 0).map(
											(elem, i) => (
												<div
													className={`flex-column gap-small grados-listing titulos ${
														TitulosSelect?.some(
															(c) => c.cftitulo === elem.cftitulo
														)
															? "selected"
															: ""
													}`}
													key={i}
													onClick={() => titulos(elem)}>
													<div className="flex-row-item gap-small space-between">
														<div className="flex-row-item space-between full-width">
															<div className="flex-row-item gap-small">
																<div className="titulo-icon">
																	{elem?.descripcion === "Fukushidoin" ? (
																		<FukushidoinIcon />
																	) : elem?.descripcion === "Shidoin" ? (
																		<ShidoinIcon />
																	) : elem?.descripcion === "Shihan" ? (
																		<ShihanIcon />
																	) : null}
																</div>
																<div className="cinturon-label">
																	{elem?.descripcion}
																</div>
															</div>
															{TitulosSelect?.some(
																(c) => c.cftitulo === elem.cftitulo
															) &&
																(elem.estado !== "" ||
																	TitulosSelect.find(
																		(item) => item.cftitulo === elem.cftitulo
																	).estado !== "") && (
																	<div
																		className="grado-estado-validacion"
																		style={{ cursor: "pointer" }}
																		onClick={(e) => {
																			e.stopPropagation();
																			if (
																				TitulosSelect.find(
																					(item) =>
																						item.cftitulo === elem.cftitulo
																				)
																			) {
																				if (
																					TitulosSelect.find(
																						(item) =>
																							item.cftitulo === elem.cftitulo
																					).valor > 0
																				) {
																					console.log("IR AL MODAL DE EXAMEN");
																				}
																			}
																		}}
																		title={
																			TitulosSelect.find(
																				(item) =>
																					item.cftitulo === elem.cftitulo
																			)
																				? TitulosSelect.find(
																						(item) =>
																							item.cftitulo === elem.cftitulo
																				).estado >= 0
																					? "Regulado por " +
																						TitulosSelect.find(
																							(item) =>
																								item.cftitulo === elem.cftitulo
																						).nombre_validador +
																					" " +
																					TitulosSelect.find(
																						(item) =>
																							item.cftitulo === elem.cftitulo
																					).apellido_validador
																					: ""
																				: ""
																		}>
																		<div
																			className={
																				(TitulosSelect.find((item) => item.cftitulo === elem.cftitulo)
																					? TitulosSelect.find((item) => item.cftitulo === elem.cftitulo).estado
																					: elem.estado) >= 0
																					? "indicador-verde"
																					: "indicador-naranja"
																			}
																		></div>
																		{TitulosSelect.find((item) => item.cftitulo === elem.cftitulo)
																			? obtenerEstado(
																				TitulosSelect.find((item) => item.cftitulo === elem.cftitulo)
																					.estado
																			)
																			: obtenerEstado(elem.estado)}
																	</div>
																)}
														</div>
														<div className="flex-row-item gap-small space-between full-width">
															<input
																className="grados-fecha"
																type="date"
																style={{ cursor: "pointer" }}
																disabled={
																	TitulosSelect.find(
																		(item) => item.cftitulo === elem.cftitulo
																	)
																		? TitulosSelect.find(
																				(item) =>
																					item.cftitulo === elem.cftitulo
																		  ).estado >= 0 &&
																		  TitulosSelect.find(
																				(item) =>
																					item.cftitulo === elem.cftitulo
																		  ).estado !== ""
																		: elem.estado !== ""
																}
																onClick={(e) => e.stopPropagation()}
																value={
																	TitulosSelect.find(
																		(item) => item.cftitulo === elem.cftitulo
																	)?.fecha ||
																	elem.fecha ||
																	""
																}
																// max={today} // Establece la fecha maxima como el día de hoy
																onChange={(self) => {
																	if (
																		TitulosSelect.find(
																			(item) => item.cftitulo === elem.cftitulo
																		)
																	) {
																		const titulo_index =
																			TitulosSelect.findIndex(
																				(c) => c.cftitulo === elem.cftitulo
																			);
																		if (
																			!perfil_admin &&
																			!perfil_adm_dojo &&
																			TitulosSelect[titulo_index].estado >= 0 &&
																			TitulosSelect[titulo_index].estado !== ""
																		) {
																			Swal.fire({
																				title: "Permiso Denegado",
																				text: "No puedes modificar este título",
																				showConfirmButton: true,
																				icon: "info",
																				heightAuto: false,
																			});
																			return;
																		}
																		if (
																			perfil_adm_dojo &&
																			perfil_dojo !== props.asociadoData?.cfDojo
																		) {
																			Swal.fire({
																				title: "Permiso Denegado",
																				text: "No puedes modificar este título, no es de tu Dojo",
																				showConfirmButton: true,
																				icon: "info",
																				heightAuto: false,
																			});
																			return;
																		}
																		const nuevosTitulos = TitulosSelect.map(
																			(item) => {
																				if (item.cftitulo === elem.cftitulo) {
																					return {
																						...item,
																						fecha: self.target.value,
																						error: "",
																					}; // Actualizamos la fecha
																				}
																				return item; // Devolvemos el objeto sin cambios si no coincide
																			}
																		);
																		// Actualizamos el estado con la nueva versión del array
																		setTitulosSelect(nuevosTitulos);
																	} else {
																		const index = Titulos.findIndex(
																			(c) => c.cftitulo === elem.cftitulo
																		);
																		const nuevosTitulos = [
																			...TitulosSelect,
																			...Titulos.filter(
																				(c, i) =>
																					c.cftitulo === Titulos[index].cftitulo && // Solo agregar el elemento en el índice dado
																					!TitulosSelect.some(
																						(selected) =>
																							selected.cftitulo === c.cftitulo
																					) // Evitar duplicados
																			).map((c) => ({
																				cftitulo: c.cftitulo,
																				fecha: self.target.value,
																				estado: "",
																			})),
																		];
																		setTitulosSelect(nuevosTitulos);
																	}
																}}
															/>
														</div>
														<div className="flex-row-item gap-small full-width space-between">
															<p className="error grados-error">
																{
																	TitulosSelect.find(
																		(item) => item.cftitulo === elem.cftitulo
																	)?.error
																}
															</p>
															<div className="flex-row-item space-between">
																<div className="checkbox" />
															</div>
														</div>
													</div>
												</div>
											)
										)}
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<div className="flex-row-item space-between">
									<div className="flex-wrapper flex-end">
										<p className="error grados-error">{Error}</p>
										<button
											title="ALT+G"
											type="button"
											className="button primary"
											onClick={guarda}>
											{loc("Aplicar")}
										</button>
										<button className="button tertiary" onClick={cierra}>
											{loc("Cancelar")}
										</button>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</main>
		);
};

export default MisGradosMod;
