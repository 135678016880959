import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import ConexContext from '../context/conex/ConexContext';
import { useHotkeys } from 'react-hotkeys-hook';

import { ReactComponent as CloseIcon } from "../assets/img/close2.svg";
import { ReactComponent as CamposIcon } from "../assets/img/layout.svg"; //Cambiar por icono de plantillas
import { ReactComponent as TrashIcon } from "../assets/img/iconos/acae_trash.svg";
import { ReactComponent as DuplicarIcon } from "../assets/img/icos-02.svg";
import { ReactComponent as CargarIcon } from "../assets/img/icos-06.svg";
import { ReactComponent as InfoIcon } from "../assets/img/opendescript2.svg";
import { ReactComponent as EditarIcon } from "../assets/img/writerojo.svg";
import { ReactComponent as GuardarIcon } from "../assets/img/iconos/acae_save.svg";

export const ModalPlantillas = ({ show, setShow, conf, setConf, campos, vistas }) => {
	const { loc, peticion } = useContext(ConexContext)//contexto
	const { lang } = useParams()
	const [plantillas, setPlantillas] = useState(null)
	const [copyPlantilla, setCopyPlantilla] = useState(null) //copia de la plantilla que se esta editando

	/**
	 * Carga las plantillas de la vista. Tambien, obtiene de nuevo las plantillas si se ha modificado o añadido alguna
	 */
	useEffect(() => {
		async function getPlantillas() {
			const pet = await peticion(`/listado/listPlantillas?app=${conf['vista']}`, { method: 'GET' })
			if (pet.estado) //algún error
				return;
			//añado las plantillas, con el campo plantilla parseado
			let plantillasAux = []
			pet.res.res.forEach((plant) => {
				plantillasAux.push({
					...plant,
					plantilla: JSON.parse(plant.plantilla),
					showDetalles: false //para mostrar los detalles de la plantilla
				})
			})
			setPlantillas(plantillasAux)
		}
		//cada vez que no haya plantillas y se muestre el modal
		if (show && conf && campos && !plantillas)
			getPlantillas();
	}, [show, conf, campos, plantillas, peticion])


	// useEffect(() => {
	// 	console.log('plantillas', plantillas)
	// }, [plantillas])

	/**
	 * Borra una plantilla a partir de su indice
	 * @param {Int} cual Indice de la plantilla a borrar
	 */
	const borrarPlantilla = async (cual) => {
		const pet = await peticion(`/listado/delPlantilla?app=${conf['vista']}&id=${plantillas[cual].id}`, { method: 'POST' })
		if (pet.estado) //algún error
			return;
		//actualizo las plantillas
		setPlantillas(null)
	}

	/**
	 * Duplica una plantilla a partir de su indice
	 * @param {Int} cual Indice de la plantilla a duplicar
	 */
	const duplicarPlantilla = async (cual) => {
		const pet = await peticion(`/listado/addPlantilla?app=${conf['vista']}&nombre=${plantillas[cual].nombre} - Copia&descripcion=${plantillas[cual].descripcion}&defecto=${false}`, { method: 'POST', json: plantillas[cual].plantilla })
		if (pet.estado) //algún error
			return;
		//actualizo las plantillas
		setPlantillas(null)
	}

	/**
	 * Modifica una plantilla a partir de su indice
	 * @param {Int} cual Indice de la plantilla que se ha modificado
	 */
	const modificaPlantilla = async (cual) => {
		const pet = await peticion(`/listado/modPlantilla?nombre=${plantillas[cual].nombre}&descripcion=${plantillas[cual].descripcion}&defecto=${plantillas[cual].defecto}&app=${conf['vista']}&id=${plantillas[cual].id}`, { method: 'POST', json: plantillas[cual].plantilla })
		if (pet.estado) //algún error
			return;
		//actualizo las plantillas
		setPlantillas(null)
	}

	/**
	 * Añade una plantilla a partir de su indice
	 * @param {Int} cual Indice de la plantilla que se va a añadir
	 * @returns 
	 */
	const anadirPlantilla = async (cual) => {
		const pet = await peticion(`/listado/addPlantilla?nombre=${plantillas[cual].nombre}&descripcion=${plantillas[cual].descripcion}&defecto=${plantillas[cual].defecto}&app=${conf['vista']}`, { method: 'POST', json: plantillas[cual].plantilla })
		if (pet.estado) //algún error
			return;
		//actualizo las plantillas
		setPlantillas(null)
	}

	/**
	 * Cierra el modal
	 */
	const cierra = () => {
		setPlantillas(null)
		setShow(false)
	}
	//atajos de teclado
	useHotkeys('esc', cierra, { enableOnContentEditable: true, enableOnFormTags: true, enabled: show !== 0 })


	if (!show || !conf || !campos)
		return ("")
	else
		return (
			<main className="modal-wrapper" >
				<div className="modal-container modal-plantillas backdrop content-wrapper" >
					<div className='modal-header'>
						<div className='flex-wrapper'>
							<CamposIcon className='section-icon' />
							<h3>{loc('Plantillas')}</h3>
						</div>
						<CloseIcon onClick={cierra} className='modal_close-icon' alt='Cerrar ventana' title='(ESC)' />
					</div>

					<div className='modal-content-scroll'>
						<div className="table-container">
							<table>
								<thead className="table-headers">
									<tr>
										{/* <td>
											<p>Por defecto</p>
										</td> */}
										<th>
											<p>Vista</p>
											{/* <img src={icos_04} alt="Vista" className="iconorojo" /> */}
										</th>
										<th>
											<p>Nombre</p>
											{/* <img src={icos_04} alt="Nombre" className="iconorojo" /> */}
										</th>
										<th>
											<p>Descripción</p>
											{/* <img src={icos_05} alt="Descripción" className="iconorojo" /> */}
										</th>
										<th>
											<p>Fecha</p>
											{/* <img src={icos_03} alt="Fecha" className="iconorojo" /> */}
										</th>
										<th>
											<p>Acciones</p>
											{/* <img src={icos_06} alt="Cargar" className="iconorojo" /> */}
										</th>
									</tr>
								</thead>
								<tbody className='table-results'>
									{/* LISTADO DE PLANTILLAS */}
									{plantillas && plantillas.map((plantilla, index) => (
										<Fragment key={index}>
											<tr>
												{/* <td style={{ width: '5%' }}>
													{!plantilla.nueva &&
														<button
															className={plantilla.defecto ? 'btn_active' : 'btn_noactive'}
															onClick={() => {
																//si es nueva no se puede cambiar el defecto
																if (plantilla.nueva) return
																//cambiar el defecto
																let plantillasAux = [...plantillas]
																plantillasAux[index].defecto = !plantillasAux[index].defecto
																plantillasAux.filter((e) => plantilla.id !== e.id).forEach((plant) => plant.defecto = false)
																setPlantillas(plantillasAux)
																modificaPlantilla(index)
															}}
														>
															<span></span>
														</button>
													}
												</td> */}
												<td>
													<p>{loc((vistas && vistas.find(elem => elem.codigo === plantilla.app)?.nombre) || '')}</p>
												</td>
												<td className='flex-wrapper multi-item-row'>
													<button
														className="unstyled-button"
														onClick={() => {
															//abro o cierro los detalles
															let plantillasAux = [...plantillas]
															plantillasAux[index].showDetalles = !plantillasAux[index].showDetalles
															setPlantillas(plantillasAux)
														}}
													>
														<InfoIcon alt="Mostrar detalles" />
													</button>
													{plantilla.nueva || plantilla.editando ?
														<>
															<input
																type="text"
																value={plantilla.nombre}
																onChange={(e) => {
																	let plantillasAux = [...plantillas]
																	plantillasAux[index].nombre = e.target.value
																	setPlantillas(plantillasAux)
																}}
															></input>
															<button className="unstyled-button"
																onClick={() => {
																	if (plantilla.nombre === '') return
																	//añadir la plantilla si es nueva
																	if (plantilla.nueva)
																		anadirPlantilla(index)
																	//si no es nueva es porque se ha modificado
																	if (plantilla.editando)
																		modificaPlantilla(index)
																}}
															>
																<GuardarIcon alt="Aplicar" />
															</button>
														</>
														:
														<>
															<p>{plantilla.nombre}</p>
															<button className="unstyled-button"
																onClick={() => {
																	let plantillasAux = [...plantillas]
																	//busco si habia alguna plantilla editando. si la hay, la restauro y cancelo la edicion de la anterior
																	if (plantillas.some(plant => plant.editando)) {
																		//restauro la plantilla
																		plantillasAux[plantillasAux.findIndex(plant => plant.editando)] = copyPlantilla
																	}
						
																	//copio la plantilla para restaurarla si se cancela la edición
																	setCopyPlantilla(JSON.parse(JSON.stringify(plantilla)))
																	plantillasAux[index].editando = true
																	setPlantillas(plantillasAux)
																}}
															>
																<EditarIcon alt="Editar" />
															</button>
														</>
													}
												</td>
												<td>
						
													{plantilla.nueva || plantilla.editando ?
														<input
															type="text"
															className="sinformato col-12"
															value={plantilla.descripcion}
															onChange={(e) => {
																let plantillasAux = [...plantillas]
																plantillasAux[index].descripcion = e.target.value
																setPlantillas(plantillasAux)
															}}
														></input>
														:
														<p>{plantilla.descripcion}</p>
													}
												</td>
												<td>
													{plantilla.fecha && new Date(plantilla.fecha).toLocaleDateString((lang + "-" + lang.toUpperCase()), { day: '2-digit', month: '2-digit', year: 'numeric' })}
												</td>
												<td className='flex-row-item action-buttons' >
													<button className="action-button unstyled-button"
														onClick={() => {
															//cargar la plantilla
															setConf({ ...conf, vista:plantilla.app, campos: plantilla.plantilla.campos, filtros: plantilla.plantilla.filtros, agregaciones: plantilla.plantilla.agregaciones, plantilla: true })
															cierra()
														}}
													>
														<CargarIcon alt="Cargar" className="iconorojo" />
													</button>
													<button className="action-button unstyled-button"
														onClick={() => {
															borrarPlantilla(index)
														}}
													>
														<TrashIcon alt="Eliminar" className="iconorojo" />
													</button>
													<button className="action-button unstyled-button"
														onClick={() => {
															duplicarPlantilla(index)
														}}
													>
														<DuplicarIcon alt="Duplicar" className="iconorojo" />
													</button>
												</td>
						
											</tr>
											{/* DETALLES DE LA PLANTILLA */}
											{plantilla.showDetalles &&
												<tr className="plantilla__detalles">
													<td>
														<button
															onClick={() => {
																//cierro los detalles
																let plantillasAux = [...plantillas]
																plantillasAux[index].showDetalles = false
																setPlantillas(plantillasAux)
															}}
														>
															<span>
																<CloseIcon alt="Cerrar detalles" className="cerrardetalles" />
															</span>
														</button>
														<ul className="plantilla__lista">
															{plantilla.plantilla.campos.length > 0 &&
																<li>
																	<span>Campos</span>:{" "}
																	{plantilla.plantilla.campos.map((campo, index) => (
																		campos[campo].nombre + (index < plantilla.plantilla.campos.length - 1 ? ', ' : '')
																	))}
																</li>
															}
															{plantilla.plantilla.filtros.filtros.length > 0 &&
																<li>
																	<span>Filtros</span>:{" "}
																	{plantilla.plantilla.filtros.filtros.map((agrupacion, indexAgrup) => ( //recorro las agrupaciones
																		<Fragment key={indexAgrup}>
																			<>(</>
																			{agrupacion.filtros.map((filtro, indexFilt) => ( //recorro los filtros de la agrupación
																				<Fragment key={indexFilt}>
																					{campos[filtro.campo].nombre} {filtro.oper} {filtro.valor}
						
																					{/* Muestra el nexo si dentro de la agrupacion hay mas de un filtro y no es el ultimo */}
																					{agrupacion.filtros.length > 1 && indexFilt < agrupacion.filtros.length - 1 &&
																						<span>{agrupacion.nexo}</span>
																					}
																				</Fragment>
																			))}
																			<>)</>
																			{/* Muestra el nexo entre agrupaciones si hay mas de una y no es la ultima */}
																			{plantilla.plantilla.filtros.filtros.length > 1 && indexAgrup < plantilla.plantilla.filtros.filtros.length - 1 &&
																				<span>{plantilla.plantilla.filtros.nexo}</span>
																			}
																		</Fragment>
																	))}
																</li>
															}
															{plantilla.plantilla.agregaciones.length > 0 &&
																<li>
																	<span>Agregaciones</span>:{" "}
																	{plantilla.plantilla.agregaciones.map((campo, index) => (
																		campos[campo].nombre + (index < plantilla.plantilla.campos.length - 1 ? ', ' : '')
																	))}
																</li>
															}
														</ul>
													</td>
												</tr>
											}
										</Fragment>
									))}
								</tbody>
							</table>
						</div>
					</div>

					{/* <div className="cabecera_filtro col-xs-2 ">
								<div className="col-xs-12 bordebtn">
									<p>Compartir "Plantiasdadlla nueva"</p>
								</div>
								<div className="col-xs-12 cont_buscador">
									<div className="col-xs-12 buscador nopadding">
										<input type="search" placeholder="Buscar" className="col-xs-10" value="" />
									</div>
									<div className="col-xs-12 separador">
										<hr/>
									</div>
									<div className="checks"></div>
								</div>
								<div className="col-xs-12 cerrar_lateral pdright2">
									<button className="aplicarbtn">aplicar</button>
								</div>
								<div className="col-xs-12 cerrar_lateral">
									<button className="cerrarbtn">cerrar</button>
								</div>
							</div>	 */}

					<div className='modal-footer'>
						<div className="flex-wrapper flex-end">
							{/* 
										Si no hay una plantilla nueva creandose, muestra el boton para crear una.
										Si hay una plantilla nueva, muestra el boton para cancelar
									*/}
							{plantillas && !plantillas.some(plantilla => plantilla.nueva || plantilla.editando) ?
						
								<div className="flex-wrapper flex-end">
									<button
										className={"button primary " + (
											conf.campos.length === 0 && conf.filtros.filtros[0].filtros.length === 0 && conf.agregaciones.length === 0 ? 'disabled' : 'enabled'
										)}
										onClick={() => {
											//añado una nueva plantilla con el conf actual
											let plantillasAux = [...plantillas]
											plantillasAux.push({
												nueva: true,
												defecto: false,
												nombre: 'Nueva plantilla',
												descripcion: '',
												plantilla: {
													campos: conf.campos,
													filtros: conf.filtros,
													agregaciones: conf.agregaciones
												},
												showDetalles: false
											})
											setPlantillas(plantillasAux)
										}}
									>
										{loc('Nueva plantilla')}
									</button>
									<button
										className="button tertiary"
										onClick={cierra}
									>
										{loc('Cancelar')}
									</button>
								</div>
								: plantillas &&
								<button
									className="button tertiary"
									onClick={() => {
										//cancelo la creación de la plantilla
										if (plantillas.some(plantilla => plantilla.nueva))
											setPlantillas(plantillas.filter(plantilla => !plantilla.nueva))
										//cancelo la edición de la plantilla
										if (plantillas.some(plantilla => plantilla.editando)) {
											let plantillasAux = [...plantillas]
											//restauro la plantilla
											plantillasAux[plantillasAux.findIndex(plantilla => plantilla.editando)] = copyPlantilla
											setPlantillas(plantillasAux)
											setCopyPlantilla(null)
										}
									}}
								>
									{loc('Cancelar')}
								</button>
							}
						</div>
					</div>
				</div>
			</main >
		)
}
