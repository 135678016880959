import React, { useContext, useEffect, useState } from "react";
import ConexContext from "../context/conex/ConexContext";
import { useParams, useNavigate, Link, useSearchParams } from "react-router-dom";
import "../assets/css/acae.css";

//SVG
import { ReactComponent as UsersIcon } from "../assets/img/iconos/acae_group.svg";
import { ReactComponent as DojoIcon } from "../assets/img/iconos/acae_dojo.svg";
import { ReactComponent as AdminIcon } from "../assets/img/iconos/acae_user.svg";
import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";
import { ReactComponent as CursoIcon } from "../assets/img/iconos/acae_course.svg";
import { ReactComponent as ViewIcon } from "../assets/img/iconos/acae_view.svg";
import { ReactComponent as EditIcon } from "../assets/img/iconos/acae_edit_1.svg";
import { ReactComponent as FukushidoinIcon } from "../assets/img/iconos/acae_t_fukushidoin.svg";
import { ReactComponent as ShidoinIcon } from "../assets/img/iconos/acae_t_shidoin.svg";
import { ReactComponent as ShihanIcon } from "../assets/img/iconos/acae_t_shihan.svg";
import { ReactComponent as AcaeArrow } from "../assets/img/iconos/acae_arrow.svg";

//IMG
// import loadingGif from "../assets/img/loading.gif";
import flor from "../assets/img/flor.svg";
import AvatarSimple from "./comun/AvatarSimple";
import MenuToggle from "./comun/menuToggle";
import InscripcionMod from "./InscripcionMod";
import MisCursosMod from "./MisCursosMod";
import MisGradosMod from "./MisGradosMod";
import CarnetAcaeBulk from "./CarnetAcaeBulk";

const AsociadosVista = ({ setSidebar, Sidebar }) => {
	const { peticion, loc, perfil_admin,
		perfil_adm_asoc,
		perfil_adm_dojo,
		perfil_asoc,
		perfil_dojo } = useContext(ConexContext);
	const navigate = useNavigate();
	const { lang, numAcae, idReg } = useParams();

	const [avatar, setAvatar] = useState("default.jpg");
	const [Cargando, setCargando] = useState(false);
	const [nombreCompleto, setNombreCompleto] = useState("");
	const [apellido, setApellido] = useState("");
	const [asociadoData, setAsociadoData] = useState(null); // Estado para almacenar la información del asociado
	const [Asociaciones, setAsociaciones] = useState([]);
	const [Cursos_ASI, setCursos_ASI] = useState([]); //cursos asistidos y pagados del asociado
	const [Grados, setGrados] = useState([]); //grados del asociado
	const [Titulos, setTitulos] = useState([]); //titulos del asociado
	const [Cinturones, setCinturones] = useState(null);
	const [mostrarGrados, setMostrarGrados] = useState(0); // modal grados cursados
	const [mostrarTodos, setMostrarTodos] = useState(0); // modal cursos cursados
	const [Dojos, setDojos] = useState([]);
	const [Cuotas, setCuotas] = useState([]);
	const [DojoCuotas, setDojoCuotas] = useState([]); //Dojo de cuotas pagadas
	const [Cursos, setCursos] = useState([]); //cursos del asociado
	// const [NRes, setNRes] = useState(0); // Nº de registros con filtro
	// const [NTot, setNTot] = useState(0); // Nº de registros con filtro
	const [VerIns, setVerIns] = useState(0); // para ver el modal de Inscribirse
	const [mostarCarnet, setMostarCarnet] = useState(0); // modal carnet
	const [ListParams, setListParams] = useState({
		num: 15,
		orden: "fecha",
		filtro: "",
		ini: 0,
		nomAso: "",
		idAsoc: 0,
		idReg: 0,
	});
	const [searchParams] = useSearchParams();

	const colCuotas = [
		"rgb(22 162 147)",
		"rgb(168 0 59)",
		"rgb(224 147 30)",
		"rgb(48 122 180)",
		"rgb(40 158 105)",
		"rgb(183 53 53)",
		"rgb(214 59 113)",
		"rgb(44 184 211)",
		"rgb(48 158 40)",
		"rgb(199 106 0)",
		"rgb(121 18 54)",
		"rgb(106 122 204)",
		"rgb(22 190 77)",
		"rgb(203 63 63)",
		"rgb(168 0 158)",
		"rgb(65 128 199)",
		"rgb(35 208 113)",
		"rgb(228 24 24)",
		"rgb(188 83 132)",
		"rgb(87 64 196)",
	];

		useEffect(() => {
			const cargarCinturones = async () => {
				try {
					const respuesta = await peticion(`/grados/cinturones`);
	
					if (respuesta?.estado === 0) {
						setCinturones(
							respuesta.res.map((obj) => ({
								...obj,
								fecha: "", // Añade la primera propiedad con el valor deseado
								estado: "", // Añade la segunda propiedad con el valor deseado
							}))
						);
					} else {
						console.error(
							"Error al cargar los cinturones:",
							respuesta?.mensaje ?? "Error desconocido"
						);
					}
	
					
				} catch (error) {
					console.error("Error al cargar los cinturones:", error);
				}
			};
			cargarCinturones();
		}, [peticion, numAcae]);

	useEffect(() => {
		if (searchParams) {
			navigate(window.location.pathname, { replace: true });
		}
	}, [searchParams, navigate]);

	useEffect(() => {
		const cargarDatos = async () => {
			try {
				setCargando(true);
				const asociado = await peticion(`/asociados/ver?numacae=${numAcae}`);
				if (asociado?.estado === 0) {
					setNombreCompleto(asociado.res.nombre);
					setApellido(asociado.res.apellido);
					setAvatar(asociado.res.avatar);
					setAsociadoData(asociado.res);

					// Cargar asociaciones relacionadas al asociado
					const asocs = await peticion(
						`/asocs/?ini=0&pag=1000&orden=nombre&filtro=`
					);
					if (asocs?.estado === 0) setAsociaciones(asocs.res);

					// Cargar dojos de la asociación del asociado
					const dojos = await peticion(
						`/dojos/?ini=0&pag=1000&orden=nombre&filtro=&idAsoc=${asociado.res.cfAsoc}`
					);
					if (dojos?.estado === 0) setDojos(dojos.res);

					// Cargar cuotas pagadas del asociado
					const cuotas = await peticion(
						`/cuotas/listCuotas?idAsoc=${asociado.res.idAso}`
					);
					setDojoCuotas(cuotas?.Dojo ?? []);
					if (cuotas?.estado === 0) setCuotas(cuotas.res);

					//cursos del asociado
					peticion(`/cursos/listCursos?idAsoc=${asociado.res.idAso}`).then((pet) => {
						if (pet.estado === 0) setCursos_ASI(pet?.res ?? []);
					});
					//grado del asociado
					peticion("/grados/gradoactual?idAsoc=" + asociado.res.idAso).then((pet) => {
						if (pet.estado === 0) {
							setGrados(pet?.res ?? []);
						}
					});
					//titulo del asociado
					peticion("/grados/tituloactual?idAsoc=" + asociado.res.idAso).then((pet) => {
						if (pet.estado === 0) {
							setTitulos(pet?.res ?? []);
						}
					});
					setCargando(false);
				} else {
					navigate(`/${lang}/app/inicio`);
				}
			} catch (error) {
				console.error("Error cargando los datos del asociado:", error);
			}
		};
		cargarDatos();
	}, [numAcae, lang, peticion, navigate]);

	// useEffect(() => {
	//     console.log(Asociaciones);
	//     console.log(Dojos);
	// }
	// , [Asociaciones, Dojos]);

	//EFFECT
	useEffect(() => {
		const idRegN = isNaN(parseInt(idReg)) ? 0 : parseInt(idReg); // id del curso en número

		if (ListParams.idReg !== idRegN) {
			return;
		} else {
			listar();
		}

		// eslint-disable-next-line
	}, [ListParams, idReg]);

	// Función para listar cursos
	const listar = async () => {
		const pet = await peticion(
			"/cursos/?" +
				`ini=${ListParams.ini}&` +
				`pag=${ListParams.num}&` +
				`orden=${ListParams.orden}&` +
				`filtro=${ListParams.filtro}`
		);
		if (pet?.estado ?? false)
			// algún error
			return null;
		setCursos(pet.res);
		// setNRes(pet.nRes);
		// setNTot(pet.nTot);
	};

	function obtenerEstado(valor) {
		if (valor < 0) {
			return loc("Pendiente");
		} else if (valor === 0) {
			return loc("Regulado");
		} else if (valor > 0) {
			return loc("Examinado");
		}
		return ""; // Por si acaso se pasa un valor que no cumpla las condiciones
	}


	return (
		<>
			<div className="content-wrapper main-section" id="inicio">
				<header className="backdrop space-between">
					<div className="flex-row-item">
						<AdminIcon className="section-icon" alt="Inicio" />
						<h1>{loc("Vista de asociado")}</h1>
					</div>
					<MenuToggle Sidebar={Sidebar} setSidebar={setSidebar} />
				</header>

				{Cargando && !VerIns && !mostarCarnet ? (
					<div className="backdrop precarga">
						<div className="loader-wrapper">
							<div className="circle-wrapper">
								<div className="circle-1" />
								<div className="circle-2" />
								<div className="circle-3" />
								<div className="circle-4" />
								<div className="circle-5" />
							</div>
							<AcaeIcon />
						</div>
						<span>{loc("Cargando...")}</span>
					</div>
				) : (
					<div className="content-wrapper">
						<div className="backdrop onboarding">
							<div className="flex-row-item space-between">
								<div className="flex-row-item gap-small">
									<h2>
										{asociadoData &&
											<Link to={`/${lang}/app/asociados/dojo_0/${asociadoData.idAso}/mod`}>
												{nombreCompleto} {apellido} <EditIcon className="asociadosvista-edit" />
											</Link>
										}
									</h2>
									<h2 className="numacae">{numAcae}</h2>
									{asociadoData &&
										<CarnetAcaeBulk
											listaAsociados={[asociadoData]} 
											obtenerTodosLosCarnets={null}
											totalCarnets={1}
											showModal={mostarCarnet}
											setShowModal={setMostarCarnet}
										/>
									}
								</div>
								<AvatarSimple avatar={avatar} />
							</div>
							{asociadoData &&
							(asociadoData.esAdmin ||
								asociadoData.esAdminAsoc ||
								asociadoData.esAdminDojo) ? (
								<>
									<div className="management">
										<div className="active_user-rights">
											{asociadoData.esAdmin && <h3>{loc("Admin ACAE")}</h3>}
											{asociadoData.esAdminAsoc && (
												<h3>{loc("Admin Asociación")}</h3>
											)}
											{asociadoData.esAdminDojo && <h3>{loc("Admin Dojo")}</h3>}
										</div>
										<div className="horizontal-divider"></div>
										<ul>
											{
											Asociaciones.length===0 ? (
													<div className="dojo_container"><p className="proximamente">{loc('Cargando Asociaciones...')}</p></div>
												):(
											Asociaciones.map(
												(elem) =>
													elem.idAsoc === asociadoData.cfAsoc && (
														<li
															className="flex-row-item gap-small"
															key={elem.idAsoc}>
															<UsersIcon />
															{perfil_admin || (perfil_adm_asoc && (elem.idAsoc === perfil_asoc)) ?
																<div className="flex-row-item gap-small flex-grow go-to">
																	<Link to={{ pathname: `/${lang}/app/dojos/asoc_${elem.idAsoc}` }}>
																		<span className="go-to__text grow text-ellipsis">Ver asociación</span>
																		<AcaeArrow className="go-to__icon" />
																	</Link>
																</div>
																: null
															}
															<div className="dojo_container">
																{elem.nombre} ({elem.zona})
															</div>
														</li>
													)
												)
											)}
										</ul>
										<ul>
											{
											Dojos.length===0 ? (
												<div className="dojo_container"><p className="proximamente">{loc('Cargando Dojos...')}</p></div>
											):(
												Dojos.map(
												(elem) =>
													elem.idDojo === asociadoData.cfDojo && (
														<li
															className="flex-row-item gap-small"
															key={elem.idDojo}>
															<DojoIcon />
															{perfil_admin || (perfil_adm_dojo && (elem.idDojo === perfil_dojo)) ?
																<div className="flex-row-item gap-small flex-grow go-to">
																	<Link to={{ pathname: `/${lang}/app/asociados/dojo_${elem.idDojo}` }}>
																		<span className="go-to__text grow text-ellipsis">Ver dojo</span>
																		<AcaeArrow className="go-to__icon" />
																	</Link>
																</div>
																: null
															}
															<div className="dojo_container">
																{elem.nombre}
															</div>
														</li>
													)
												)
											)}
										</ul>
									</div>
								</>
							) : (
								<>
									<div className="management">
										<div className="horizontal-divider"></div>
										<ul>
											<li className="flex-row-item gap-small">
												<UsersIcon />
												{perfil_admin || perfil_adm_asoc || perfil_adm_dojo ?
													<div className="flex-row-item gap-small flex-grow go-to">
														<Link to={{ pathname: `/${lang}/app/dojos/asoc_${asociadoData?.cfAsoc}` }}>
															<span className="go-to__text grow text-ellipsis">Ver asociación</span>
															<AcaeArrow className="go-to__icon" />
														</Link>
													</div>
													:
													null
												}
												<div className="dojo_container">
													{asociadoData?.nombreAsoc}
												</div>
											</li>
											<li className="flex-row-item gap-small">
												<DojoIcon />
												{perfil_admin || (perfil_adm_dojo && (asociadoData?.cfDojo === perfil_dojo)) ?
													<div className="flex-row-item gap-small flex-grow go-to">
														<Link to={{ pathname: `/${lang}/app/asociados/dojo_${asociadoData?.cfDojo}` }}>
															<span className="go-to__text grow text-ellipsis">Ver dojo</span>
															<AcaeArrow className="go-to__icon" />
														</Link>
													</div>
													:
													null
												}
												<div className="dojo_container">
													{asociadoData?.nombreDojo}
												</div>
											</li>
										</ul>
									</div>
								</>
							)}
						</div>

						<div className="grid">
							<div className="backdrop paid-fees">
								<div className="content-wrapper">
									<h2>{loc("Últimas cuotas")}</h2>
									<ul className="flex-row-item gap-small">
									{Cuotas.length > 0 ? (
										Cuotas.map((el, index) => (
											<li
												className="flex-row-item gap-small"
												key={index}
												style={{
													background: colCuotas[el % 20],
												}}
												title={DojoCuotas && DojoCuotas[index]?.Dojo ? `Pagado en ${DojoCuotas[index].Dojo}` : "Información no disponible"}>
												<span>{el}</span>
												<img src={flor} alt="" />
											</li>
										))
									) : (<div className="dcontent-wrapper"><p className="proximamente">No hay cuotas pagadas</p></div>)}
									</ul>
								</div>
							</div>
							<div className="backdrop grados-overview">
							<div className="content-wrapper">
									<h2>{loc("Grados")}</h2>
									{Grados.filter((el) => el.estado >= 0).length > 0 ? (
										<div className="grid card gap-small">
											{Grados
											.filter((el) => el.estado >= 0) // Filtra elementos con estado >= 0
											.sort((a, b) => b.valor - a.valor) // Ordena de mayor a menor por "valor"
											.slice(0, 1) // Toma los primeros 3 elementos
											.map((el) => (
												<div
													className="flex-column gap-small grados-listing dan grados-lista"
													key={el.idgrado}
												>
													<div className="flex-column gap-small">
														<div className="flex-row-item space-between">
															<div className="cinturon-label">{el?.descripcion}</div>
															<div
															className="grado-color"
															style={{
																background: (() => {
																const cinturonActualIndex = Cinturones?.findIndex(
																	(cinturon) => cinturon.idcinturon === el.idcinturon
																);
																const cinturonActual = Cinturones?.[cinturonActualIndex];

																const colorAnterior =
																	Cinturones?.[cinturonActualIndex - 1]?.color_primario || "transparent";
																const colorSiguiente =
																	Cinturones?.[cinturonActualIndex + 1]?.color_primario || "transparent";

																return cinturonActual?.valor % 10 !== 0
																	? `linear-gradient(90deg, ${colorAnterior} 50%, ${colorSiguiente} 50%)`
																	: cinturonActual?.color_primario || "transparent";
																})(),
															}}
															/>
														</div>
														<div className="flex-row-item space-between">
															<p className="date">{el.fecha}</p>
															<div className="estado-container">{obtenerEstado(el.estado)}</div>
														</div>
													</div>
												</div>
											))}
											{Titulos && Titulos.filter((el) => el.estado >= 0).length > 0 ? (
												<>
													<h3>{loc("Títulos")}</h3>
													{Titulos
													.filter((el) => el.estado >= 0) // Filtra elementos con estado >= 0
													.sort((a, b) => b.valor - a.valor) // Ordena de mayor a menor por "valor"
													.slice(0, 1) // Toma 1 elemento
													.map((el) => (
														<div
															className="flex-column gap-small grados-listing dan grados-lista"
															key={el?.idasoc_titulo}
														>
															<div className="flex-column gap-small">
																<div className="flex-row-item space-between">
																	<div className="flex-row-item space-between">
																		<div className="cinturon-label">{el?.descripcion}</div>
																	</div>
																	<div className="titulo-icon black">
																		{ el?.descripcion === "Fukushidoin" ?
																			<FukushidoinIcon />
																		:
																			el?.descripcion === "Shidoin" ?
																			<ShidoinIcon />
																		:
																			el?.descripcion === "Shihan" ?
																			<ShihanIcon />
																		:
																		null
																		}
																	</div>
																</div>
																<div className="flex-row-item space-between">
																	<p className="date">{el?.fecha}</p>
																	<div className="estado-container">{obtenerEstado(el.estado)}</div>
																</div>
															</div>
														</div>
													))}
												</>
											):(
												<div className="dcontent-wrapper"><p className="proximamente">{loc("No tienes titulos regulados")}</p></div>
											)}
										</div>
									) : (
										<p className="proximamente">{loc("No tienes grados regulados")}</p>
									)}
									<button
										className="cta"
										onClick={() => setMostrarGrados(asociadoData.idAso)}>
										{loc("Gestionar grados")}
									</button>
								</div>
							</div>
							<div className="backdrop cursos-overview">
							<div className="content-wrapper">
									<div className="flex-row-item gap-small">
										<h2>{loc("Mis cursos")}</h2><span className="estado-container">{Cursos_ASI.length}</span>
									</div>
									<ul className="list-view-curso grid">
										{Cursos_ASI.length > 0 ? (
											<>
												{Cursos_ASI.slice(0, 4).map((el) => (
													<li key={el.idcurso} className="cursos-lista">
														<div className="flex-row-item gap-small">
															<AcaeIcon />
															<p className="location">{el?.ciudad}</p>
														</div>
														<p className="date">
															{
																new Intl.DateTimeFormat((lang + "-" + lang.toUpperCase()), {
																	day: "numeric",
																}).format(new Date(el.fechaini)).toUpperCase()
															}
															{" — "}
															{
																new Intl.DateTimeFormat((lang + "-" + lang.toUpperCase()), {
																	day: "numeric",
																	month: "short",
																	year: "numeric",
																}).format(new Date(el.fechafin)).toUpperCase()
															}
														</p>
													</li>
												))}
											</>
										) : (
											<p className="proximamente">{loc("No tienes cursos")}</p>
										)}
									</ul>
									{Cursos_ASI.length > 0 &&
										<button
											className="button ver-mas__lite"
											onClick={() => setMostrarTodos(asociadoData.idAso)}>
											{loc("Ver todos")}
										</button>
									}
								</div>
							</div>
						</div>
						<div className="backdrop cursos-disponibles">
							<div className="content-wrapper">
								<h2>Próximos cursos</h2>
								<ul className="quick-view-curso grid">
									{Cursos.length > 0 ? (
										Cursos.map((curso) =>
											new Date(curso.fechaFin) >= new Date() &&
											curso.cfValAdmi ? (
												<li className="quick-view-curso__item" key={curso.idCurso}>
													<div>
														<h4>{curso.titulo}</h4>
														<div className="flex-row-item gap-small">
															<p className="date">
																{new Intl.DateTimeFormat((lang + "-" + lang.toUpperCase()), {
																	day: "numeric",
																	month: "long",
																})
																	.format(new Date(curso.fechaIni))
																	.toUpperCase()}
															</p>
															{new Date(curso.fechaIni) < new Date() ? (
																<div className="estado-curso">
																	{loc("En curso")}
																</div>
															) : ( null ) }
														</div>
														<div className="flex-row-item gap-small">
															<CursoIcon />
															<p className="location">{curso.ciudad}</p>
														</div>
													</div>
													<button
														className="cta"
														onClick={() => {
															setVerIns(curso.idCurso);
														}}>
														Apúntate
													</button>
												</li>
											) : null
										)
									) : (
										<p className="proximamente">No hay cursos disponibles</p>
									)}
								</ul>
							</div>
						</div>
						<div className="backdrop tribunales-disponibles">
							<div className="content-wrapper">
								<h2>Tribunales</h2>
								<p className="proximamente">Próximamente disponible</p>
							</div>
						</div>

						<InscripcionMod
							show={VerIns}
							ListParams={ListParams}
							setListParams={setListParams}
							onHide={() => setVerIns(0)}
							idAsociado={asociadoData?.idAso}
						/>
					</div>
				)}
			</div>
			<MisCursosMod show={mostrarTodos} onHide={() => setMostrarTodos(0)} />
			<MisGradosMod
				show={mostrarGrados}
				onHide={() => setMostrarGrados(0)}
				asociadoData={asociadoData}
				setGrados={setGrados}
				perfil={asociadoData?.idAso}
			/>
		</>
	);
};

export default AsociadosVista;
