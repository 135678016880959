import React, { useState, useContext, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";

// Contexto
import ConexContext from "../context/conex/ConexContext";

// SVGs
import { ReactComponent as ViewIcon } from "../assets/img/iconos/acae_view.svg";
import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";
import { ReactComponent as CarnetIcon } from "../assets/img/iconos/acae_carnet.svg";
import { ReactComponent as CheckIcon } from "../assets/img/iconos/acae_check.svg";
import { ReactComponent as CloseIcon } from "../assets/img/iconos/acae_close.svg";
import { ReactComponent as AcaeArrow } from "../assets/img/iconos/acae_arrow.svg";

// Imágenes
import desc from "../assets/img/desc.svg";
import asc from "../assets/img/asc.svg";

// Módulos
import Paginacion from "./comun/Paginacion";
import MenuToggle from "./comun/menuToggle";
import CarnetAcaeBulk from "./CarnetAcaeBulk";

const Carnets = ({ setSidebar, Sidebar }) => {
	const { peticion, loc, perfil_admin, perfil_adm_asoc, Cargando } =
		useContext(ConexContext);
	const { lang } = useParams();

	const [List, setList] = useState([]);
	const [NRes, setNRes] = useState(0); // nº de registros con filtro
	const [NTot, setNTot] = useState(0); // nº de registros sin filtro
	const [NPrint, setNPrint] = useState(0); // nº de registros imprimibles (sin mail)
	const [showModal, setShowModal] = useState(false);
	const [ListParams, setListParams] = useState({
		num: 15,
		orden: "numAcae",
		filtro: "",
		ini: 0,
		idDojo: 0,
		nomDoj: "",
		cfAsoc: 0,
		idReg: 0,
	});
	const [selectedAsociados, setSelectedAsociados] = useState({});
	const [selectedArray, setSelectedArray] = useState([]);
	const [firstClick, setFirstClick] = useState(true);
	const [Listado, setListado] = useState([]);

	const totalSeleccionados = firstClick ? NPrint : selectedArray.length;

	useEffect(() => {
		const listar = async () => {
			const pet = await peticion(
				`/asociados/asociadosCarnets?ini=${ListParams.ini}&pag=${ListParams.num}&orden=${ListParams.orden}&filtro=${ListParams.filtro}&idDojo=${ListParams.idDojo}`
			);
			if (pet?.estado) return;
			setList(pet.res);
			setNTot(pet.nTot);
			setNPrint(pet.nPrint);
			setNRes(pet.nRes);
		};
		listar();
	}, [ListParams, peticion]);

	useEffect(() => {
		if (!showModal) {
			setListado([]);
		}
	}, [showModal]);

	const obtenerTodosLosCarnets = async () => {
		const pet = await peticion(
			`/asociados/asociadosCarnets?ini=0&pag=${NTot}&orden=${ListParams.orden}&idDojo=${ListParams.idDojo}`
		);
		if (pet?.estado) return;
		setListado(pet.res);
	};

	const handleCheckboxChange = (idAso) => {
		const asociado = List.find((a) => a.idAso === idAso);
		setSelectedAsociados((prev) => {
			if (firstClick) {
				setFirstClick(false);
				setSelectedArray([asociado]);
				return { [idAso]: true };
			} else {
				const newSelected = { ...prev, [idAso]: !prev[idAso] };
				if (newSelected[idAso]) {
					// Si se marca, lo agregamos si no existe
					setSelectedArray((old) => {
						if (!old.find((item) => item.idAso === idAso)) {
							return [...old, asociado];
						}
						return old;
					});
				} else {
					// Si es el último, volvemos al estado inicial de selección con firstClick
					if (selectedArray.length === 1) {
						setSelectedAsociados({});
						setSelectedArray([]);
						setFirstClick(true);
					} else {
						// Si se desmarca, lo quitamos
						setSelectedArray((old) => old.filter((item) => item.idAso !== idAso));
					}
				}
				return newSelected;
			}
		});
	};

	const orden = (campo) => {
		if (ListParams.orden === campo)
			setListParams({ ...ListParams, orden: campo + " DESC", idReg: 0 });
		else setListParams({ ...ListParams, orden: campo, idReg: 0 });
	};

	useEffect(() => {
		if (Object.keys(selectedAsociados).length === 0 && !firstClick) {
			setFirstClick(true);
		}
	}, [selectedAsociados, firstClick]);

	return (
		<>
			<div className="content-wrapper main-section" id="carnets">
				<header className="backdrop space-between">
					<div className="flex-row-item">
						<CarnetIcon className="section-icon" alt="Carnets" />
						<h1>{loc("Carnets")}</h1>
					</div>
					<MenuToggle Sidebar={Sidebar} setSidebar={setSidebar} />
				</header>

				{Cargando && !showModal ? (
					<div className="backdrop precarga">
						<div className="loader-wrapper">
							<div className="circle-wrapper">
								<div className="circle-1" />
								<div className="circle-2" />
								<div className="circle-3" />
								<div className="circle-4" />
								<div className="circle-5" />
							</div>
							<AcaeIcon />
						</div>
						<span>{loc("Cargando...")}</span>
					</div>
				) : (
					<div className="backdrop content-wrapper">
						<div className="filtering">
							<Paginacion
								nrPag={List.length}
								NRes={NRes}
								NTot={NTot}
								Ini={ListParams.ini}
								setListParams={setListParams}
								Num={ListParams.num}
								Filtro={ListParams.filtro}
							/>
						</div>
						<div className="table-container">
							<table className="table-asociados">
								<thead className="table-headers">
									<tr>
										<th className="tiny-column"></th>
										<th className="wide-column">
											<button onClick={() => orden("numAcae")}>
												{loc("Num ACAE")}
											</button>
											<ul className="listaordena">
												{ListParams.orden === "numAcae" && (
													<li>
														<img src={asc} alt="ascendente" />
													</li>
												)}
												{ListParams.orden === "numAcae DESC" && (
													<li>
														<img src={desc} alt="descendente" />
													</li>
												)}
											</ul>
										</th>
										<th className="ocultamovil wide-column grow-column">
											<button onClick={() => orden("nombre")}>
												{loc("Nombre")}
											</button>
											<ul className="listaordena">
												{ListParams.orden === "nombre" && (
													<li>
														<img src={asc} alt="ascendente" />
													</li>
												)}
												{ListParams.orden === "nombre DESC" && (
													<li>
														<img src={desc} alt="descendente" />
													</li>
												)}
											</ul>
										</th>
										<th className="ocultamovil">
											<button onClick={() => orden("dojo")}>
												{loc("Dojo")}
											</button>
											<ul className="listaordena">
												{ListParams.orden === "dojo" && (
													<li>
														<img src={asc} alt="ascendente" />
													</li>
												)}
												{ListParams.orden === "dojo DESC" && (
													<li>
														<img src={desc} alt="descendente" />
													</li>
												)}
											</ul>
										</th>
										<th className="ocultamovil large-column">
											<button onClick={() => orden("mail")}>
												{loc("Imprimible")}
											</button>
											<ul className="listaordena">
												{ListParams.orden === "imprimible" && (
													<li>
														<img src={asc} alt="ascendente" />
													</li>
												)}
												{ListParams.orden === "imprimible DESC" && (
													<li>
														<img src={desc} alt="descendente" />
													</li>
												)}
											</ul>
										</th>
										<th className="ocultamovil medium-column">
											<button onClick={() => orden("ultima_cuota")}>
												{loc("Últ. Cuota")}
											</button>
											<ul className="listaordena">
												{ListParams.orden === "ultima_cuota" && (
													<li>
														<img src={asc} alt="ascendente" />
													</li>
												)}
												{ListParams.orden === "ultima_cuota DESC" && (
													<li>
														<img src={desc} alt="descendente" />
													</li>
												)}
											</ul>
										</th>
										<th className="ocultamovil medium-column">
											<button onClick={() => orden("edad")}>
												{loc("Adulto")}
											</button>
											<ul className="listaordena">
												{ListParams.orden === "edad" && (
													<li>
														<img src={asc} alt="ascendente" />
													</li>
												)}
												{ListParams.orden === "edad DESC" && (
													<li>
														<img src={desc} alt="descendente" />
													</li>
												)}
											</ul>
										</th>
									</tr>
								</thead>
								<tbody className="table-results">
									{List.map((elem) => (
										<Fragment key={elem.idAso}>
											<tr
												className={`${
													parseInt(elem.idAso) === parseInt(ListParams.idReg)
														? "selected"
														: ""
												} ${elem?.mail ? "" : "disable"}`}>
												<td className="tiny-column">
													<input
														className={`carnet-checkbox ${
															firstClick && elem.mail ? "default" : ""
														}`}
														type="checkbox"
														checked={!!selectedAsociados[elem.idAso]}
														onChange={() => handleCheckboxChange(elem.idAso)}
														disabled={!elem.mail}
													/>
												</td>
												<td className="avatar-row wide-column">
													<img
														src={"/avatars/" + (elem.avatar ? elem.avatar : "default.jpg")}
														alt=""
													/>
													<span>{elem.numAcae}</span>
												</td>
												<td className="table-button wide-column grow-column black ocultamovil">
													<div className="flex-row-item gap-small flex-grow">
														<Link
															to={`/${lang}/app/asociados/usuario/${elem.numAcae}`}
															className="cell-td-button"
															title={loc("Ir a asociado")}>
															<span className="cell-td-button--label grow text-ellipsis">
																{elem?.nombre} {elem.apellido} {!elem.mail && "*"}
															</span>
															<AcaeArrow className="cell-td-button--label-icon" />
														</Link>
													</div>
												</td>
												<td className="table-button black ocultamovil">
													{perfil_admin || perfil_adm_asoc ? (
														<div className="flex-row-item gap-small flex-grow">
															<Link
																to={{
																	pathname: `/${lang}/app/asociados/dojo_${elem.codigo_dojo}`,
																}}
																className="cell-td-button"
																title={loc("Ir a Dojo")}>
																<span className="cell-td-button--label grow text-ellipsis">
																	{elem?.dojo}
																</span>
																<AcaeArrow className="cell-td-button--label-icon" />
															</Link>
														</div>
													) : (
														<span>{elem?.dojo}</span>
													)}
												</td>
												<td className="avatar-row large-column">
													{elem.mail ? (
														<p className="guardado red">
															<CheckIcon />{" "}
														</p>
													) : (
														<p className="guardado red">
															<CloseIcon />
														</p>
													)}
												</td>
												<td className="avatar-row medium-column">
													<span>{elem.ultima_cuota}</span>
												</td>
												<td className="avatar-row medium-column">
													<span>{elem.edad === "A" ? "Adulto" : "Niño"}</span>
												</td>
											</tr>
										</Fragment>
									))}
								</tbody>
							</table>
							<div className="anotacion-tabla">(*) Asociado sin email</div>
						</div>
						<div className="flex-row-item gap-small">
							{/* Botón de Previsualización / Descarga en masa */}
								<CarnetAcaeBulk
									listaAsociados={firstClick ? Listado.filter(a => a.mail) : selectedArray.filter(a => a.mail)}
									obtenerTodosLosCarnets={obtenerTodosLosCarnets}
									totalCarnets={totalSeleccionados}
									firstClick={firstClick}
									showModal={showModal}
									setShowModal={setShowModal}
								/>
								<button
									className={`button tertiary ${firstClick && "disabled"}`}
									onClick={() => {
									setSelectedAsociados({});
									setSelectedArray([]);
									setFirstClick(true);
								}}>
								<span>
									{!firstClick
										? "Deseleccionar todo"
										: "Todos los asociados seleccionados"}
								</span>
							</button>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Carnets;
