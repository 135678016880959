import React, { useRef, useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import QRCode from "qrcode";

import "../assets/css/carnetacae.css";
import carnetBg from "../assets/img/maestro-carnet.jpg";
import logoAcae from "../assets/img/logo-acae-completo.png";
import { ReactComponent as Kanji } from "../assets/img/kanji-aikido.svg";
import { ReactComponent as DescargaIcon } from "../assets/img/iconos/acae_download.svg";
import { ReactComponent as CloseIcon } from "../assets/img/iconos/acae_close.svg";
import { ReactComponent as CarnetIcon } from "../assets/img/iconos/acae_carnet.svg";

const CarnetAcaeBulk = ({
	listaAsociados,
	obtenerTodosLosCarnets,
	totalCarnets,
	firstClick,
	showModal,
	setShowModal,
}) => {
	const carnetsRef = useRef(null);
	const [qrcodes, setQRCodes] = useState({});
	const [isDownloading, setIsDownloading] = useState(false);
	const [progress, setProgress] = useState(0);
	const isCancellingRef = useRef(false);

	useEffect(() => {
		const generateAllQRCodes = async () => {
			const qrMap = {};
			if (listaAsociados && listaAsociados.length > 0) {
				for (let asociado of listaAsociados) {
					const domain = window.location.origin;
					const url = `${domain}/es/app/asociados/usuario/${asociado.numAcae}?mail=${asociado.mail}&valCode=${asociado.codvalidacion}`;
					qrMap[asociado.numAcae] = await QRCode.toDataURL(url);
				}
			}
			setQRCodes(qrMap);
		};
		generateAllQRCodes();
	}, [listaAsociados]);

	const handleOpenModal = async () => {
		setShowModal(true);
		if (firstClick && listaAsociados.length === 0) {
			// Si no se ha hecho selección manual y la lista está vacía, obtener TODOS los carnets
			await obtenerTodosLosCarnets();
		}
	};

	const handleDownloadBulkPDF = async () => {
		setIsDownloading(true);
		setProgress(0);
		isCancellingRef.current = false;

		const CARNET_WIDTH = 103.6;
		const CARNET_HEIGHT = 71.98;

		const pdf = new jsPDF({
			orientation: "landscape",
			unit: "mm",
			format: [CARNET_WIDTH, CARNET_HEIGHT],
		});

		const carnetElements = carnetsRef.current.children;

		for (let i = 0; i < carnetElements.length; i += 2) {
			if (isCancellingRef.current) {
				console.log("Descarga cancelada.");
				setIsDownloading(false);
				setProgress(0);
				return;
			}

			const carnetFrontal = carnetElements[i];
			const carnetReverso = carnetElements[i + 1];

			try {
				const canvasFront = await html2canvas(carnetFrontal, {
					scale: window.devicePixelRatio * 1.5,
					useCORS: true,
					backgroundColor: "white",
				});
				const imgDataFront = canvasFront.toDataURL("image/png");
				if (i > 0) pdf.addPage([CARNET_WIDTH, CARNET_HEIGHT]);
				pdf.addImage(imgDataFront, "PNG", 0, 0, CARNET_WIDTH, CARNET_HEIGHT);

				const canvasBack = await html2canvas(carnetReverso, {
					scale: window.devicePixelRatio * 1.5,
					useCORS: true,
					backgroundColor: "white",
				});
				const imgDataBack = canvasBack.toDataURL("image/png");
				pdf.addPage([CARNET_WIDTH, CARNET_HEIGHT]);
				pdf.addImage(imgDataBack, "PNG", 0, 0, CARNET_WIDTH, CARNET_HEIGHT);

				setProgress(Math.round(((i + 2) / carnetElements.length) * 100));
			} catch (error) {
				console.error("Error al generar el PDF:", error);
			}
		}

		if (!isCancellingRef.current) {
			pdf.save("carnets-asociados.pdf");
		}

		setIsDownloading(false);
		setProgress(0);
	};

	const cancelDownloadBulkPDF = () => {
		console.log("Cancelando descarga...");
		isCancellingRef.current = true;
	};

	const formatNombreApellido = (nombre, apellido) => {
		let nombreProcesado = nombre.trim().split(" ");
		let apellidoProcesado = apellido.trim();
		if (nombreProcesado.length > 1) {
			nombreProcesado = [
				nombreProcesado[0],
				...nombreProcesado.slice(1).map((n) => n[0] + "."),
			];
		}
		let nombreFinal = `${nombreProcesado.join(" ")} ${apellidoProcesado}`;
		if (nombreFinal.length > 30) {
			nombreProcesado[0] = nombreProcesado[0][0] + ".";
			nombreFinal = `${nombreProcesado.join(" ")} ${apellidoProcesado}`;
		}
		return nombreFinal;
	};

	return (
		<div>
			<button
				type="button"
				className="cta carnetacae__button"
				onClick={handleOpenModal}>
				<span className="carnetacae__button-text">
					{totalCarnets > 1 ? "Previsualizar carnets" : "Previsualizar carnet"}
				</span>
				<div className="notification-embed">{totalCarnets}</div>
			</button>

			{showModal ? (
				<main className="modal-wrapper modal-carnetacae">
					<div className="modal-container backdrop content-wrapper">
						<div className="modal-header">
							<div className="flex-wrapper">
								<CarnetIcon className="section-icon asociados" />
								<h3>Carnets</h3>
							</div>
							<CloseIcon
								onClick={() => setShowModal(false)}
								className="modal_close-icon"
								alt="Cerrar ventana"
								title="(ESC)"
							/>
						</div>
						<div className="modal-content-scroll carnets">
							<div className="modal-content-outer-wrapper">
								<div ref={carnetsRef} className="carnet-container">
									{listaAsociados.map((asociado) => (
										<React.Fragment key={asociado.idAso}>
											{/* Frontal del carnet */}
											<div
												className="carnetacae__margen"
												style={{
													width: "103.6mm",
													height: "71.98mm",
													backgroundColor: "white",
												}}>
												<div className="carnetacae__cortes">
													<div className="carnetacae__marcas-corte--superior"></div>
													<div className="carnetacae__marcas-corte--derecho"></div>
													<div className="carnetacae__marcas-corte--inferior"></div>
													<div className="carnetacae__marcas-corte--izquierdo"></div>
													<div className="carnetacae__margen">
														<div className="carnetacae__bg">
															<img src={carnetBg} alt="Background" />
														</div>
														<div className="carnetacae__carnet">
															<div className="carnetacae__avatar">
																<img
																	className="carnetacae__avatar--img"
																	src={
																		asociado.avatar
																			? `/avatars/${asociado.avatar}`
																			: "/avatars/default.jpg"
																	}
																	alt="Avatar"
																/>
															</div>
															<div className="carnetacae__content">
																<div className="carnetacae__italic">
																	{asociado.numAcae}
																</div>
																<div className="carnetacae__nombre">
																	{formatNombreApellido(
																		asociado.nombre,
																		asociado.apellido
																	)}
																</div>
																<div className="carnetacae__dojo">
																	{asociado.nombreDojo}
																</div>
																<div className="carnetacae__italic">
																	Asociación Cultura Aikikai España
																</div>
																<div className="carnetacae__flex-container">
																	<img
																		src={logoAcae}
																		className="carnetacae__logo"
																		alt="Logo"
																	/>
																	<Kanji className="carnetacae__kanji" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/* Reverso del carnet */}
											<div
												className="carnetacae__margen"
												style={{
													width: "103.6mm",
													height: "71.98mm",
													backgroundColor: "white",
												}}>
												<div className="carnetacae__cortes">
													<div className="carnetacae__marcas-corte--superior"></div>
													<div className="carnetacae__marcas-corte--derecho"></div>
													<div className="carnetacae__marcas-corte--inferior"></div>
													<div className="carnetacae__marcas-corte--izquierdo"></div>
													<div className="carnetacae__margen">
														<div className="carnetacae__carnet">
															<div className="carnetacae__content--back">
																<div className="carnetacae__qr--container">
																	<span className="carnetacae__nombre">
																		{asociado.numAcae}
																	</span>
																	{qrcodes[asociado.numAcae] && (
																		<img
																			src={qrcodes[asociado.numAcae]}
																			className="carnetacae__qr"
																			alt="QR"
																		/>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</React.Fragment>
									))}
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<div className="flex-wrapper flex-end">
								{!isDownloading ? (
									<button
										className="button cta carnetacae__button"
										onClick={handleDownloadBulkPDF}>
										<span className="carnetacae__button-text">
											{listaAsociados.length > 1 ? "Carnets" : "Carnet"}
										</span>
										<DescargaIcon className="carnetacae__button-download" />
									</button>
								) : (
									<button
										className="button cta carnetacae__button cancel"
										onClick={cancelDownloadBulkPDF}>
										<span className="carnetacae__button-text">
											Generando...
										</span>
										<CloseIcon className="carnetacae__button-download" />
										<div
											className="carnetacae__progress"
											style={{ width: progress + "%" }}></div>
									</button>
								)}
								<button
									className="button tertiary"
									onClick={() => setShowModal(false)}>
									Cancelar
								</button>
							</div>
						</div>
					</div>
				</main>
			) : null }
		</div>
	);
};

export default CarnetAcaeBulk;
