const ConexReducer=(state, action) => {
	const { payload, type } = action;
	let perfil
	switch (type) {
		case 'SET_LOCALE':
			return {
				...state,
				locale: payload,
			};
		case 'SET_CARGANDO':
			return {
				...state,
				Cargando: payload,
			};
		case 'SET_JWT':
			if (payload!=='')
				perfil=JSON.parse(window.atob(payload.split('.')[1]))
			else{//borramos 
				delete(localStorage.token)
				return{...state, token: null}
			}
			localStorage.token=payload// lo guardamos tb localmente
			return{
				...state,
				token: payload,
				perfil_id:perfil.id,
				perfil_admin:perfil.admin,
				perfil_adm_asoc:perfil.adm_asoc,
				perfil_adm_dojo:perfil.adm_dojo,
				perfil_admin_orig:perfil.admin_orig,
				perfil_adm_asoc_orig:perfil.adm_asoc_orig,
				perfil_adm_dojo_orig:perfil.adm_dojo_orig,
				perfil_dojo:perfil.dojo,
				perfil_asoc:perfil.asoc,
				perfil_avatar:perfil.avatar,
			}
		default:
			return state;
	}
};
export default ConexReducer;