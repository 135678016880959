import React, { useState, useContext, useEffect, Fragment } from 'react'
import { useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
//Contexto
import ConexContext from "../context/conex/ConexContext";

//SVG
import { ReactComponent as GradoIcon } from "../assets/img/iconos/acae_belt.svg";
import { ReactComponent as ValidarIcon } from "../assets/img/iconos/acae_check.svg";
import { ReactComponent as QuitarIcon } from "../assets/img/iconos/acae_close.svg";
import { ReactComponent as AcaeArrow } from "../assets/img/iconos/acae_arrow.svg";
import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";
import { ReactComponent as DownloadIcon } from "../assets/img/download.svg";
import { ReactComponent as TrashIcon } from "../assets/img/iconos/acae_trash.svg";

//imagenes
import desc from "../assets/img/desc.svg"
import asc from "../assets/img/asc.svg"

//modulos
import Paginacion from './comun/Paginacion';
import MenuToggle from './comun/menuToggle';


const Grados = ({ setTimer, setSidebar, Sidebar }) => {
	const {
		peticion,
		loc,
		perfil_admin,
		// perfil_adm_asoc,
		perfil_adm_dojo,
		// perfil_dojo,
		// perfil_id,
		peticionPDF,
		Cargando,
	} = useContext(ConexContext); //contexto
	
	const {
		idReg,
		lang
	} = useParams();

	//STATES
	const [List, setList] = useState([]); // listado
	const [NRes, setNRes] = useState(0); // Nº de registros con filtro
	const [NTot, setNTot] = useState(0); // Nº de registros con filtro
	const [ListParams, setListParams] = useState({
		num: 15,
		orden: "fecha DESC",
		filtro: "",
		ini: 0,
		nomAso: "",
		idAsoc: 0,
		idReg: 0,
	});

	//borra un registro concreto
	// const borrar = async (id) => {};

	//cambia el orden
	const orden = (campo) => {
		if (ListParams.orden === campo)
			setListParams({ ...ListParams, orden: campo + " DESC" });
		else setListParams({ ...ListParams, orden: campo });
	};

	function obtenerEstado(valor) {
		if (valor < 0) {
		return "Pendiente";
		} else if (valor === 0) {
		return "Regulado";
		} else if (valor > 0) {
		return "Examinado";
		}
		return ""; // Por si acaso se pasa un valor que no cumpla las condiciones
	}

	//EFFECT
	useEffect(() => {
		const idRegN = isNaN(parseInt(idReg)) ? 0 : parseInt(idReg); // id del curso en número

		if (ListParams.idReg !== idRegN) {
			getPosicion(idRegN);
		} else {
			listar();
		}

		// eslint-disable-next-line
	}, [ListParams, idReg]);

	// Función para listar cursos
	const listar = async () => {
		const pet = await peticion(
			"/grados/?" +
				`ini=${ListParams.ini}&` +
				`pag=${ListParams.num}&` +
				`orden=${ListParams.orden}&` +
				`filtro=${ListParams.filtro}`
		);
		if (pet?.estado ?? false)
			// algún error
			return null;
		setList(pet.res);
		setNRes(pet.nRes);
		setNTot(pet.nTot);
	};

	//borra un registro concreto
	const borrar = async (id) => {
		const pet = await peticion("/grados/delUnGrado", {
			method: "POST",
			json: {
				idGrado: id,
			},
		});
		if (pet?.estado ?? 0 === 9) {
			Swal.fire({
				title: loc("El grado no se puede eliminar."),
				text: loc("Ha habido un error al intentar eliminar el grado"),
				showConfirmButton: true,
				icon: "error",
			});
			return;
		}
		if (pet?.estado ?? true)
			//algún error
			return;
		Swal.fire({
			title: loc("Grado eliminado."),
			showConfirmButton: false,
			icon: "success",
			timer: 1500,
		});
		setListParams({ ...ListParams });
	};

	//borra un titulo concreto
	const borrarTitulo = async (id) => {
		const pet = await peticion("/grados/delUnTitulo", {
			method: "POST",
			json: {
				idTitulo: id,
			},
		});
		if (pet?.estado ?? 0 === 9) {
			Swal.fire({
				title: loc("El Título no se puede eliminar."),
				text: loc("Ha habido un error al intentar eliminar el título"),
				showConfirmButton: true,
				icon: "error",
			});
			return;
		}
		if (pet?.estado ?? true)
			//algún error
			return;
		Swal.fire({
			title: loc("Título eliminado."),
			showConfirmButton: false,
			icon: "success",
			timer: 1500,
		});
		setListParams({ ...ListParams });
	};

	// Función para obtener la posición de un grado específico
	const getPosicion = async (id) => {};

	// Función para alternar la validación de un grado
	async function validarCurso(id) {
		try {
			const pet = await peticion("/grados/validarGrado", {
				method: "POST",
				json: {
					idGrado: id,
				},
			});
			if (pet?.estado ?? false) return; // algún error
			setTimer(0)
			// Refresca la lista de cursos después de cambiar el estado de validación
			listar();
		} catch (error) {
			console.error("Error al validar el grado:", error);
		}
	}

	// Función para alternar la validación de un grado
	async function validarTitulo(id) {
		try {
			const pet = await peticion("/grados/validarTitulo", {
				method: "POST",
				json: {
					idTitulo: id,
				},
			});
			if (pet?.estado ?? false) return; // algún error
			setTimer(0)
			// Refresca la lista de cursos después de cambiar el estado de validación
			listar();
		} catch (error) {
			console.error("Error al validar el titulo:", error);
		}
	}

	const PeticionExcel = async () => {
		try {
			peticionPDF(
				`/grados/descargar_doc_danes`,
				{ method: "POST" }
			).then(async (res) => {
				if (!res) {
					Swal.fire({
						title: "Listado erróneo",
						text: "Ha habido un problema en el servidor",
						showConfirmButton: true,
						icon: "error",
						heightAuto: false,
					});
					return;
				}
			});
		} catch (error) {
			console.error("Error al descargar el archivo", error);
		}
	};

	return (
		<>
			<div className="content-wrapper main-section" id="cursos">
				<header className="backdrop space-between">
					<div className="flex-row-item">
						<GradoIcon className="section-icon" alt={loc("Grados")} />
						<h1>{loc("Grados")}</h1>
					</div>
					<MenuToggle Sidebar={Sidebar} setSidebar={setSidebar} />
				</header>

				{Cargando ? (
					<div className="backdrop precarga">
						<div className="loader-wrapper">
							<div className="circle-wrapper">
								<div className="circle-1" />
								<div className="circle-2" />
								<div className="circle-3" />
								<div className="circle-4" />
								<div className="circle-5" />
							</div>
							<AcaeIcon />
						</div>
						<span>
							{loc("Cargando...")}
						</span>
					</div>
				) : (
					<>
						<div className="backdrop content-wrapper">
							<div className="filtering">
								<Paginacion
									nrPag={List.length}
									NRes={NRes}
									NTot={NTot}
									Ini={ListParams.ini}
									setListParams={setListParams}
									Num={ListParams.num}
									Filtro={ListParams.filtro}
								/>
							</div>
							{List && List.length>0 ? (
								<>
								<div className="table-container">
								<table className="table-cursos">
									<thead className="table-headers">
										<tr>
											<th className="large-s-column grow-column">
												<button onClick={() => orden("nombre")}>
													{loc("Nombre y apellidos")}
												</button>
												<ul className="listaordena">
													{ListParams.orden === "nombre" && (
														<li>
															<img src={asc} alt="ordena ascendente" />
														</li>
													)}
													{ListParams.orden === "nombre DESC" && (
														<li>
															<img src={desc} alt="ordena descendente" />
														</li>
													)}
												</ul>
											</th>
											<th className="large-s-column">
												<button onClick={() => orden("descripcion")}>
													{loc("Descripción")}
												</button>
												<ul className="listaordena">
													{ListParams.orden === "descripcion" && (
														<li>
															<img src={asc} alt="ordena ascendente" />
														</li>
													)}
													{ListParams.orden === "descripcion DESC" && (
														<li>
															<img src={desc} alt="ordena descendente" />
														</li>
													)}
												</ul>
											</th>
											<th className="large-s-column">
												<button onClick={() => orden("fecha")}>
													{loc("Fecha")}
												</button>
												<ul className="listaordena">
													{ListParams.orden === "fecha" && (
														<li>
															<img src={asc} alt="ordena ascendente" />
														</li>
													)}
													{ListParams.orden === "fecha DESC" && (
														<li>
															<img src={desc} alt="ordena descendente" />
														</li>
													)}
												</ul>
											</th>
											<th className="large-s-column grow-column">
												<button>{loc("Regularizado por")}</button>
											</th>
											<th className="large-column">
												<button onClick={() => orden("validacion")}>
													{loc("Validación")}
												</button>
												<ul className="listaordena">
													{ListParams.orden === "validacion" && (
														<li>
															<img src={asc} alt="ordena ascendente" />
														</li>
													)}
													{ListParams.orden === "validacion DESC" && (
														<li>
															<img src={desc} alt="ordena descendente" />
														</li>
													)}
												</ul>
											</th>
											<th className="table-actions hide-text medium-column">Ac</th>
										</tr>
									</thead>
									<tbody className="table-results">
										{List.map((elem) => (
											<Fragment key={elem.idgrado || elem.idasoc_titulo}>
												<tr	className={	elem.validacion === -1 ? "apuntado": ""}>
													<td className="table-button large-s-column grow-column">
														<div className='flex-row-item gap-small flex-grow'>
															<Link to={`/${lang}/app/asociados/usuario/${elem.numacae}`} className='cell-td-button' title={loc('Ir a asociado')}>
																<span className="cell-td-button--label grow text-ellipsis">
																	{elem?.nombre} {elem?.apellido}
																</span>
																<AcaeArrow className="cell-td-button--label-icon" />
															</Link>
														</div>
													</td>
													<td className="large-s-column">{elem?.descripcion}</td>
													<td className="large-s-column">{
															new Intl.DateTimeFormat((lang + "-" + lang.toUpperCase()), {
																day: "numeric",
																month: "short",
																year: "numeric",
															}).format(new Date(elem?.fecha)).toUpperCase()
														}</td>
													{/* <td className="medium-column">{elem?.nhoras}</td> */}
													{/* <td className="large-s-column">{elem?.cfvalidador}</td> */}
													<td className="table-button large-s-column grow-column">
														{elem?.cfvalidador ? (
															<span className="grow text-ellipsis">
																{elem?.valor > 0 ? ('Administradores de ACAE'):(elem?.nombre2+" "+elem?.apellido2)}
															</span>
														):(
															<span className="grow text-ellipsis">
															</span>
														)}
														
													</td>
													<td
														className={`large-column estado-wrapper ${
															elem.validacion!==-1 ? "aprobado" : ""
														}`}>
															<div
															className={`estado-container`}>
															{loc(obtenerEstado(elem?.validacion))}
														</div>
														{(perfil_admin || (perfil_adm_dojo && elem?.valor < 0)) ? (
															elem.validacion !== -1 ? (
																<div>
																	<QuitarIcon
																		onClick={() => elem?.idgrado ? validarCurso(elem?.idgrado):validarTitulo(elem?.idasoc_titulo)}
																	/>
																</div>
															) : (
																<div>
																	<ValidarIcon
																		onClick={() => elem?.idgrado ? validarCurso(elem?.idgrado):validarTitulo(elem?.idasoc_titulo)}
																	/>
																</div>
															)
														) : (
															""
														)}
													</td>
													<td className="ocultamovil table-actions medium-column">
														<ul className="flex-row-item no-gap">
															{elem?.validacion >= 0 ? (
																	""
																) : (
																	<li>
																		<TrashIcon
																			alt="trash"
																			onClick={() =>
																				Swal.fire({
																					title: loc(
																						`¿Seguro que quieres eliminar el ${elem?.idgrado? ("grado"):("título")}?`
																					),
																					showCancelButton: true,
																					confirmButtonText: loc("Sí"),
																					icon: "question",
																					cancelButtonText: loc("No"),
																				}).then((result) => {
																					if (result.isConfirmed){
																						if (elem?.idgrado){
																							borrar(elem?.idgrado);
																						}else{
																							borrarTitulo(elem?.idasoc_titulo);
																						}
																					}
																				})
																			}
																		/>
																	</li>
																)
															}
														</ul>
													</td>
												</tr>
											</Fragment>
										))}
									</tbody>
								</table>
							</div>
							{perfil_admin && (
								<div>
									<button
										className="button primary"
										onClick={() => {
											PeticionExcel();
										}}>
										<DownloadIcon /> {loc("Documentación para la Comisión técnica")}
									</button>
								</div>
							)}
							</>
							):(
								<div className="dcontent-wrapper"><p className="proximamente">{loc("No hay grados actualmente")}</p></div>
							)}
							
						</div>
						{/* MODALES */}
					</>
				)}
			</div>
		</>
	);
};

export default Grados;
