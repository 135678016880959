import React, { useState, useContext, useEffect, Fragment, useRef } from 'react'
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from 'sweetalert2'
import { useHotkeys } from 'react-hotkeys-hook';

//Contexto
import ConexContext from "../context/conex/ConexContext";

//SVG
import { ReactComponent as WriteIcon } from "../assets/img/iconos/acae_edit_1.svg";
import { ReactComponent as TrashIcon } from "../assets/img/iconos/acae_trash.svg";
import { ReactComponent as CuotaIcon } from "../assets/img/iconos/acae_fees.svg";
import { ReactComponent as MasIcon } from "../assets/img/iconos/acae_add.svg";
import { ReactComponent as ValidarIcon } from "../assets/img/iconos/acae_check.svg";
import { ReactComponent as QuitarIcon } from "../assets/img/iconos/acae_close.svg";
import { ReactComponent as ViewIcon } from "../assets/img/iconos/acae_view.svg";

import { ReactComponent as DojoIcon } from "../assets/img/iconos/acae_dojo.svg";
import { ReactComponent as AsocIcon } from "../assets/img/iconos/acae_group.svg";
import { ReactComponent as AdminIcon } from "../assets/img/iconos/acae_flower.svg";
import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";
import { ReactComponent as AcaeArrow } from "../assets/img/iconos/acae_arrow.svg";

//imagenes
import desc from "../assets/img/desc.svg"
import asc from "../assets/img/asc.svg"

//modulos
import CuotasMod from './CuotasMod'
import Paginacion from './comun/Paginacion';
import MenuToggle from './comun/menuToggle';

const Remesas = ({ setTimer, setSidebar, Sidebar }) => {
	const {
		peticion,
		loc,
		perfil_admin,
		perfil_adm_dojo,
		perfil_adm_asoc,
		perfil_dojo,
		perfil_asoc,
		Cargando
	} = useContext(ConexContext)//contexto
	const { idReg, lang } = useParams();
	const navigate = useNavigate();

	//REFs
	const rTipo = useRef();

	//STATES
	const [VerMod, setVerMod] = useState(0)// para ver el modal de Ficheros
	const [List, setList] = useState([])// listado
	const [NRes, setNRes] = useState(0)// Nº de registros con filtro
	const [NTot, setNTot] = useState(0)// Nº de registros con filtro
	const [ListParams, setListParams] = useState({
		num: 15,
		orden: 'anio DESC',
		filtro: '',
		ini: 0,
		idReg: 0
	})
	const [Selec, setSelec] = useState([]) //checks seleccionados 
	const [tipo, settipo] = useState('')// Filtro de cuotas dojo=Esta validado por Dojo, asoc=Esta validado por Asociaciom, ''=Todos ....
	// const [tipo1, settipo1] = useState('dojo_3')// Filtro de cuotas dojo_1=Esta validado, dojo_2=No esta validado, dojo_3=Todos
	// const [tipo2, settipo2] = useState('asoc_3')// Filtro de cuotas asoc_1=Esta validado, asoc_2=No esta validado, asoc_3=Todos
	// const [tipo3, settipo3] = useState('admin_3')// Filtro de cuotas admin_1=Esta validado, admin_2=No esta validado, admin_3=Todos

	//atajos de teclado
	useHotkeys('alt+n', () => { if (perfil_adm_dojo) setVerMod(-1) })
	// FUNCIONES
	//se va a poder seleccionar?
	const esClicable = (el) => {
		let val = false
		if (perfil_adm_dojo & (el?.valNiv === 1 | el?.desValNiv === 1)) val = true
		if (perfil_adm_asoc & (el?.valNiv === 2 | el?.desValNiv === 2)) val = true
		if (perfil_admin & (el?.valNiv === 3 | el?.desValNiv === 3)) val = true
		return val
	}
	const esPendiente = (el) => {
		let val = false
		if (perfil_adm_dojo & (el.cfAsocValDojo === 0)) val = true
		if (perfil_adm_asoc & (el.cfAsocValAsoc === 0)) val = true
		if (perfil_admin & (el.cfAsocValAdmi === 0)) val = true
		return val
	}
	const esValidable = () => {
		if (Selec.length === 0) return false
		const el0 = List.filter((el) => el.idRem === Selec[0])[0]
		//son todos los valore iguales
		const numIg = List.reduce((a, b) => { if (a && Selec.includes(b.idRem)) return (el0?.valNiv === b?.valNiv); return (a) }, true)
		if (!numIg) return false
		if (perfil_adm_dojo & el0?.valNiv === 1) return true
		if (perfil_adm_asoc & el0?.valNiv === 2) return true
		if (perfil_admin & el0?.valNiv === 3) return true
		return false
	}

	const esDesValidable = () => {
		if (Selec.length === 0) return false
		const el0 = List.filter((el) => el.idRem === Selec[0])[0]
		//son todos los valore iguales
		const numIg = List.reduce((a, b) => { if (a && Selec.includes(b.idRem)) return (el0?.desValNiv === b?.desValNiv); return (a) }, true)
		if (!numIg) return false
		if (perfil_adm_dojo & el0?.desValNiv === 1) return true
		if (perfil_adm_asoc & el0?.desValNiv === 2) return true
		if (perfil_admin & el0?.desValNiv === 3) return true
		return false
	}

	//quita o pone un elemento del Select 
	const toggleSelec = (elem) => {
		const a = [...Selec]
		var index = a.indexOf(elem.idRem);
		if (index === -1) {
			if (!esClicable(elem)) return
			if (Selec.length > 0) {
				const el0 = List.filter((el) => el.idRem === Selec[0])[0]
				if (el0?.valNiv !== elem?.valNiv && (el0?.desValNiv !== elem?.desValNiv | el0?.desValNiv === 0)) {
					Swal.fire({
						title: loc("No puedes seleccionar remesas validadas y no validadas al mismo tiempo"),
						showConfirmButton: false,
						icon: 'warning',
						timer: 1500
					})
					return;
				}
				if (el0.anio !== elem.anio) {
					Swal.fire({
						title: loc("No puedes validar dos años diferentes."),
						showConfirmButton: false,
						icon: 'warning',
						timer: 1500
					})
					return;
				}
			}
			a.push(elem.idRem);
		}
		else
			a.splice(index, 1);
		setSelec(a)
	}

	//borra un registro concreto
	const borrar = async (id) => {
		const pet = await peticion('/cuotas/del', {
			method: 'POST', json: {
				id: id
			}
		})
		if (pet?.estado ?? 0 === 9) {
			Swal.fire({
				title: loc("La remesa no se puede eliminar."),
				text: loc("La remesa ya está validada."),
				showConfirmButton: true,
				icon: 'error',
			})
			return
		}
		if (pet?.estado ?? true)//algún error 
			return;
		Swal.fire({
			title: loc("Remesa de cuotas eliminada."),
			showConfirmButton: false,
			icon: 'success',
			timer: 1500
		})
		setListParams({ ...ListParams })
	}
	// valida cuotas
	const valida = async (validar) => {
		const el0 = List.filter((el) => el.idRem === Selec[0])[0]
		const pet = await peticion('/cuotas/valida', {
			method: 'POST', json: {
				selec: Selec,
				valida: validar,
				nivel: validar ? el0?.valNiv : el0?.desValNiv
			}
		})
		if (pet?.estado ?? 0 === 11) {
			Swal.fire({
				title: loc("La remesa no se ha podido validar."),
				showConfirmButton: true,
				icon: 'error',
			})
			return
		}
		if (pet?.estado ?? true)//algún error 
			return;
		Swal.fire({
			title: validar ? loc("Remesa de cuotas validada.") : loc("Remesa de cuotas desvalidada."),
			showConfirmButton: false,
			icon: 'success',
			timer: 1500
		})
		setSelec([])
		setTimer(0)
		setListParams({ ...ListParams })
	}

	//cambia el orden
	const orden = (campo) => {
		if (ListParams.orden === campo)
			setListParams({ ...ListParams, orden: campo + ' DESC' })
		else
			setListParams({ ...ListParams, orden: campo })
	}

	//EFFECT
	useEffect(() => {
		//listado en sí de los asociados
		const listar = async () => {

			const pet = await peticion('/cuotas/?' +
				`ini=${ListParams.ini}&` +
				`pag=${ListParams.num}&` +
				`orden=${ListParams.orden}&` +
				`filtro=${ListParams.filtro}&` +
				`tipo=${tipo}&`
			)
			if (pet?.estado ?? false)//algún error 
				return null;
			// vamos a enriquecer el listado, para saber si es validable o desvalidable
			pet.res.forEach(el => {
				el.valNiv = 0
				el.desValNiv = 0
				if (perfil_adm_dojo & el.cfDojo === perfil_dojo) {
					if (el.cfAsocValDojo === 0) el.valNiv = 1
					else if (el.cfAsocValAsoc === 0) el.desValNiv = 1
				}
				if (perfil_adm_asoc & el.cfAsoc === perfil_asoc & el.cfAsocValDojo !== 0) {
					if (el.cfAsocValAsoc === 0) el.valNiv = 2
					else if (el.cfAsocValAdmi === 0) el.desValNiv = 2
				}
				if (perfil_admin & el.cfAsocValAsoc !== 0) {
					if (el.cfAsocValAdmi === 0) el.valNiv = 3
					else el.desValNiv = 3
				}
			});
			setList(pet.res)
			setNRes(pet.nRes)
			setNTot(pet.nTot)
		}
		//devuelve la pos de un id
		const getPosicion = async (id) => {
			const pet = await peticion('/cuotas/posicion?' +
				`id=${id}&` +
				`orden=${ListParams.orden}&` +
				`filtro=${ListParams.filtro}`)
			if (pet?.estado ?? false)//algún error 
				return;
			if (pet?.res?.pos ?? false) {
				const pos = pet.res.pos - (pet.res.pos - 1) % ListParams.num - 1
				if (pos !== ListParams.ini)
					setListParams((prev) => ({
						...prev,
						ini: pet.res.pos - (pet.res.pos - 1) % ListParams.num - 1,
						idReg: id
					}))
				else setListParams((prev) => ({ ...prev, idReg: id }))
			}
			else navigate(`/${lang}/app/cuotas/`)
		}
		const idRegN = (isNaN(parseInt(idReg)) ? 0 : parseInt(idReg))//id de la remesa de la url en número
		// console.log('idRegN',idRegN,ListParams.idReg);
		if (ListParams.idReg !== idRegN)// hay que listar un asociado concreto
			getPosicion(idRegN)
		else {
			listar()
		}
		// eslint-disable-next-line
	}, [ListParams, idReg, lang, navigate, perfil_adm_asoc, perfil_adm_dojo, perfil_admin, perfil_asoc, perfil_dojo, tipo])
	
	return (
		<>
			<div className='content-wrapper main-section' id='cuotas'>
				<header className='backdrop space-between'>
					<div className='flex-row-item'>
						<CuotaIcon className='section-icon' alt='Cuotas' />
						<h1>
							{loc('Cuotas')}
						</h1>
					</div>
					<MenuToggle Sidebar={Sidebar} setSidebar={setSidebar} />

				</header>

				{Cargando && !VerMod ? (
					<div className="backdrop precarga">
						<div className="loader-wrapper">
							<div className="circle-wrapper">
								<div className="circle-1" />
								<div className="circle-2" />
								<div className="circle-3" />
								<div className="circle-4" />
								<div className="circle-5" />
							</div>
							<AcaeIcon />
						</div>
						<span>
							{loc("Cargando...")}
						</span>
					</div>
				) : (
					<div className='backdrop content-wrapper'>
						<div className='filtering flex-row-item'>
							<Paginacion
								nrPag={List.length}
								NRes={NRes}
								NTot={NTot}
								Ini={ListParams.ini}
								setListParams={setListParams}
								Num={ListParams.num}
								Filtro={ListParams.filtro}
							/>
							<div className='flex-wrapper cuotas-filtro'>
								<div className='n-results search'>
									<select
										className='view-input'
										ref={rTipo}
										value={tipo}
										onChange={()=> settipo(rTipo.current.value)}
									>
										<option value={''}>Todos</option>
										<option value={'dojo'}>{("Validados por el Dojo")}</option>
										<option value={'asoc'}>{("Validados por la Asociación")}</option>
										<option value={'admin'}>{("Validados por el Administrador")}</option>
										<option value={'no_dojo'}>{("No validados por el Dojo")}</option>
										<option value={'no_asoc'}>{("No validados por la Asociación")}</option>
										<option value={'no_admin'}>{("No validados por el Administrador")}</option>
									</select>
								</div>
								{/* <ul>
									<li>
										<button 
											// className={tipo === 'dojo' ? ('button cta'):('button tertiary')} 
											className='button cta'
											onClick={()=>{
													if (tipo1 === 'dojo_1'){
														settipo1('dojo_2')
													}
													if (tipo1 === 'dojo_2'){
														settipo1('dojo_3')
													}
													if (tipo1 === 'dojo_3'){
														settipo1('dojo_1')
													}
												}
											}
											title={loc('Validado por el dojo')}
											>
											{tipo1 === 'dojo_1' && <DojoIcon className='fees-filter__type-1' />}
											{tipo1 === 'dojo_2' && <DojoIcon className='fees-filter__type-2' />}
											{tipo1 === 'dojo_3' && <DojoIcon className='fees-filter__type-3' />}
										</button>
									</li>
									<li>
										<button 
											// className={tipo === 'asoc' ? ('button cta'):('button tertiary')}
											className='button cta'
											onClick={()=>{
												if (tipo2 === 'asoc_1'){
													settipo2('asoc_2')
												}
												if (tipo2 === 'asoc_2'){
													settipo2('asoc_3')
												}
												if (tipo2 === 'asoc_3'){
													settipo2('asoc_1')
												}
												}
											}
											title={loc('Validado por la asociación')}
											>
											{tipo2 === 'asoc_1' && <AsocIcon className='fees-filter__type-1' />}
											{tipo2 === 'asoc_2' && <AsocIcon className='fees-filter__type-2' />}
											{tipo2 === 'asoc_3' && <AsocIcon className='fees-filter__type-3' />}
										</button>
									</li>
									<li>
										<button 
											// className={tipo === 'admin' ? ('button cta'):('button tertiary')} 
											className='button cta'
											onClick={()=>{
												if (tipo3 === 'admin_1'){
													settipo3('admin_2')
												}
												if (tipo3 === 'admin_2'){
													settipo3('admin_3')
												}
												if (tipo3 === 'admin_3'){
													settipo3('admin_1')
												}
												}
												
											}
											title={loc('Validado por el administrador')}
											>
											{tipo3 === 'admin_1' && <AdminIcon className='fees-filter__type-1' />}
											{tipo3 === 'admin_2' && <AdminIcon className='fees-filter__type-2' />}
											{tipo3 === 'admin_3' && <AdminIcon className='fees-filter__type-3' />}
										</button>
									</li>
								</ul> */}
							</div>
						</div>

						<div className="table-container">
							<table className="table-cuotas">
								<thead className="table-headers">
									<tr>
										<th className='tiny-column'>
										</th>
										<th className='short-column'>
											<button onClick={() => orden('idRemesa')}>{loc('ID')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'idRemesa' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'idRemesa DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th>
										<th className='wide-column grow-column'>
											<button onClick={() => orden('dojo')}>{loc('Dojo')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'dojo' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'dojo DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th>
										<th className='wide-column grow-column'>
											<button onClick={() => orden('asociacion')}>{loc('Asociación')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'asociacion' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'asociacion DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th>
										<th className='ocultamovil medium-column'>
											<button onClick={() => orden('anio')}>{loc('Año')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'anio' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'anio DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th>
										<th className='ocultamovil medium-column'>
											<button onClick={() => orden('numAd')}>{loc('Adultos')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'numAd' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'numAd DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th>
										<th className='ocultamovil medium-column'>
											<button onClick={() => orden('numNi')}>{loc('Niños')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'numNi' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'numNi DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th>
										<th className='display wide-column' >
											{loc('Validaciones')}
										</th>
										<th className='ocultamovil hide-text table-actions'>Ac</th>
									</tr>
								</thead>
								<tbody className='table-results'>
									{List
									// .filter((objeto) => {
									// 		// Condiciones para cada tipo
									// 		const cumpleFiltroTipo1 = tipo1 === "dojo_3" || // Mostrar todos
									// 		(tipo1 === "dojo_1" && objeto.cfAsocValDojo !== 0) || // Valor distinto de 0
									// 		(tipo1 === "dojo_2" && objeto.cfAsocValDojo === 0);   // Valor igual a 0

									// 		const cumpleFiltroTipo2 = tipo2 === "asoc_3" || // Mostrar todos
									// 			(tipo2 === "asoc_1" && objeto.cfAsocValAsoc !== 0) || // Valor distinto de 0
									// 			(tipo2 === "asoc_2" && objeto.cfAsocValAsoc === 0);   // Valor igual a 0

									// 		const cumpleFiltroTipo3 = tipo3 === "admin_3" || // Mostrar todos
									// 		(tipo3 === "admin_1" && objeto.cfAsocValAdmi !== 0) || // Valor distinto de 0
									// 		(tipo3 === "admin_2" && objeto.cfAsocValAdmi === 0);   // Valor igual a 0
									// 		// Solo incluir elementos que cumplan con todos los filtros
									// 		return cumpleFiltroTipo1 && cumpleFiltroTipo2 && cumpleFiltroTipo3;
									// 	})
										.map((elem) => (
										<Fragment key={elem.idRem}>
											<tr className={(esClicable(elem) ? 'clickable ' : '') + (esPendiente(elem) ? 'notificar ' : '') + (Selec.includes(elem.idRem) ? 'selected' : '')}>
												<td className="tiny-column">
													{esClicable(elem) ?
													<input
														className={`carnet-checkbox`}
														type="checkbox"
														checked={Selec.includes(elem.idRem)}
														onChange={() => toggleSelec(elem)}
													/>
													: null}
												</td>
												{/* <td className="wide-column">
													{esClicable(elem) ?
													<div className="inscripcion estado-container" onClick={() => toggleSelec(elem)}>
														{(Selec.includes(elem.idRem) ? loc("Quitar") : loc("Añadir"))}
													</div> : null}
												</td> */}
												<td className='short-column'>{elem.idRem}</td>
												<td className='table-button black wide-column grow-column'>
													{/* {elem.dojo}
													{perfil_admin || (perfil_adm_dojo && (elem.cfDojo === perfil_dojo)) ?
														<Link to={{ pathname: `/${lang}/app/asociados/dojo_${elem.cfDojo}` }}>
															<button>
																<ViewIcon />
															</button>
														</Link> : null
													} */}
													{ perfil_admin || perfil_adm_asoc ? (
														<div className='flex-row-item gap-small flex-grow'>
															<Link to={{ pathname: `/${lang}/app/asociados/dojo_${elem.cfDojo}` }} className='cell-td-button' title={loc('Ir a Dojo')}>
																<span className="cell-td-button--label grow text-ellipsis">
																	{elem?.dojo}
																</span>
																<AcaeArrow className="cell-td-button--label-icon" />
															</Link>
														</div>
														) : (elem.dojo)
													}
												</td>
												{/* <td>
												{Selec.includes(elem.idRem) ?
													<img src={pin} alt="" className={'imgpin' + (!esClicable(elem) ? ' invisible' : '')} />
													:
													<img src={nopin} alt="" className={'imgpin' + (!esClicable(elem) ? ' invisible' : '')} />
												}
												{elem.dojo}
											</td> */}
												<td className='table-button black wide-column grow-column'>
													<div className='flex-row-item gap-small flex-grow'>
														<Link to={{ pathname: `/${lang}/app/dojos/asoc_${elem.cfAsoc}` }} className='cell-td-button' title={loc('Ir a Asociación')}>
															<span className="cell-td-button--label grow text-ellipsis">
																{elem?.asociacion}
															</span>
															<AcaeArrow className="cell-td-button--label-icon" />
														</Link>
													</div>
												</td>
												<td className='ocultamovil medium-column'>{elem.anio}</td>
												<td className='ocultamovil medium-column'>{elem.numAd}</td>
												<td className='ocultamovil medium-column'>{elem.numNi}</td>
												<td className='validation-group wide-column'>
													<DojoIcon alt='Validar'
														className={elem.cfAsocValDojo === 0 ? 'unvalidated' : 'validated'}
														title={elem.cfAsocValDojo === 0 ? loc('Pendiente de validar por el dojo') : loc('Validado por el dojo')}
													/>

													<AsocIcon alt='Validar'
														className={elem.cfAsocValAsoc === 0 ? 'unvalidated' : 'validated'}
														title={elem.cfAsocValAsoc === 0 ? loc('Pendiente de validar por la asociación') : loc('Validado por la asociación')}
													/>

													<AdminIcon alt='Validar'
														className={elem.cfAsocValAdmi === 0 ? 'unvalidated' : 'validated'}
														title={elem.cfAsocValAdmi === 0 ? loc('Pendiente de validar por el administrador') : loc('Validado por el administrador')}
													/>
												</td>
												{/* <td className='texto_rojo'>
												{(
													perfil_adm_dojo && elem.cfAsocValDojo === 0 |
													perfil_adm_asoc && elem.cfAsocValAsoc === 0 |
													perfil_admin && elem.cfAsocValAdmi === 0
												) ?
													loc("Pte.") : ''

												}
											</td> */}
												<td className='table-actions'>
													{elem.cfAsocValDojo !== 0 ?
														<>
															<ul className="flex-row-item no-gap">
																<li>
																	<ViewIcon onClick={(event) => { setVerMod(elem.idRem); event.stopPropagation() }} />
																</li>
															</ul>
														</>
														:
														<>
															<ul className="flex-row-item no-gap">
																<li>
																	<WriteIcon alt='write' onClick={(event) => { setVerMod(elem.idRem); event.stopPropagation() }} />
																</li>
																<li>
																	<TrashIcon alt='trash' onClick={(event) => {
																		Swal.fire({
																			title: "¿Seguro que quieres eliminar la remesa de cuotas?",
																			showCancelButton: true,
																			confirmButtonText: "Sí",
																			icon: 'question',
																			cancelButtonText: 'No'
																		}).then((result) => {
																			if (result.isConfirmed) borrar(elem.idRem)
																		});
																		event.stopPropagation()
																	}} />
																</li>
															</ul>
														</>
													}
												</td>
											</tr>
											{/* <tr className='borde_gris'></tr>
										<tr className='listaoculta'>
											<td colSpan={6}>
												<ul>
													<li>{elem.asociacion}</li>
													<li>{elem.anio}</li>
													<li>{elem.numAd}</li>
													<li>{elem.numNi}</li>
												</ul>
											</td>
										</tr> */}
										</Fragment>
									))

									}
								</tbody>
							</table>
						</div>
						
						<div className='flex-wrapper button-container space-between gap-large'>
							<div className='flex-wrapper button-container'>
								{perfil_adm_dojo &&
									<div>
										<button className="cta" onClick={() => setVerMod(-1)}>
											<span>
												{loc('Añadir nueva remesa de cuotas')}
											</span>
											<MasIcon />
										</button>
									</div>
								}
								<div className='flex-row-item gap-small'>
									{List.filter(el => el?.valNiv !== 0).length > 0 &&
										<div>
											<button className={"cta" + (!esValidable() ? ' disabled' : '')} title='ALT-N' onClick={() => {
												Swal.fire({
													title: loc('Vas a validar las remesas seleccionadas. ¿Estás seguro?'),
													text: loc('Asegúrate de que se ha hecho transferencia con el importe del total de todas las cuotas: ') +
														` (${List.filter((el) => Selec.includes(el.idRem)).reduce((tot, el) => tot + el.numAd, 0)} ${loc('adultos')}, y ${List.filter((el) => Selec.includes(el.idRem)).reduce((tot, el) => tot + el.numNi, 0)} ${loc('niños')})`,
													showCancelButton: true,
													confirmButtonText: loc('Sí'),
													icon: 'question',
													cancelButtonText: loc('No')
												}).then((result) => {
													if (result.isConfirmed) valida(true)
												});
											}}>
												{!esValidable() ?
													<><ValidarIcon /></>
													:
													<><ValidarIcon /></>
												}
											</button>
										</div>
									}
									{List.filter(el => el?.desValNiv !== 0).length > 0 &&
										<button className={"cta" + (!esDesValidable() ? ' disabled' : '')} title='ALT-N' onClick={() => {
											Swal.fire({
												title: loc('Vas a desvalidar las remesas seleccionadas. ¿Estás seguro?'),
												showCancelButton: true,
												confirmButtonText: loc('Sí'),
												icon: 'question',
												cancelButtonText: loc('No')
											}).then((result) => {
												if (result.isConfirmed) valida(false)
											});
										}}>
											{!esDesValidable() ?
												<><QuitarIcon /></>
												:
												<><QuitarIcon /></>
											}
										</button>
									}
								</div>
							</div>
							
						</div>
						<CuotasMod
							show={VerMod}
							ListParams={ListParams}
							setListParams={setListParams}
							onHide={() => setVerMod(0)}
						/>

					</div>
				)}
			</div>
		</>
	)
}

export default Remesas