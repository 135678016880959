import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import ConexContext from "../context/conex/ConexContext";
import "../assets/css/acae.css";
import Swal from "sweetalert2";

//SVG
import { ReactComponent as InicioIcon } from "../assets/img//iconos/acae_home.svg";
import { ReactComponent as UsersIcon } from "../assets/img/iconos/acae_group.svg";
import { ReactComponent as DojoIcon } from "../assets/img/iconos/acae_dojo.svg";
import { ReactComponent as CursoIcon } from "../assets/img/iconos/acae_course.svg";
import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";
import { ReactComponent as ViewIcon } from "../assets/img/iconos/acae_view.svg";
import { ReactComponent as AcaeArrow } from "../assets/img/iconos/acae_arrow.svg";

import { ReactComponent as FukushidoinIcon } from "../assets/img/iconos/acae_t_fukushidoin.svg";
import { ReactComponent as ShidoinIcon } from "../assets/img/iconos/acae_t_shidoin.svg";
import { ReactComponent as ShihanIcon } from "../assets/img/iconos/acae_t_shihan.svg";

//IMG
// import loadingGif from "../assets/img/loading.gif";
import flor from "../assets/img/flor.svg";
import AvatarSimple from "./comun/AvatarSimple";
import MenuToggle from "./comun/menuToggle";
import InscripcionMod from "./InscripcionMod";
import MisCursosMod from "./MisCursosMod";
import MisGradosMod from "./MisGradosMod";
import CarnetAcaeBulk from "./CarnetAcaeBulk";

export const Inicio = ({ setSidebar, Sidebar }) => {
	const {
		peticion,
		loc,
		perfil_id,
		perfil_admin,
		perfil_adm_asoc,
		perfil_adm_dojo,
		perfil_admin_orig,
		perfil_adm_asoc_orig,
		perfil_adm_dojo_orig,
		perfil_asoc,
		perfil_dojo,
		Cargando,
	} = useContext(ConexContext); // Contexto
	const { idReg, lang } = useParams();

	const [avatar, setAvatar] = useState("default.jpg");
	const [nombreCompleto, setNombreCompleto] = useState(""); // Estado para almacenar el nombre del asociado
	const [numAcae, setNumAcae] = useState(""); // Estado para almacenar el número de acae
	const [Asociaciones, setAsociaciones] = useState([]); //clase con las asociaciones
	const [Dojos, setDojos] = useState([]); //clase con los dojos
	const [asociacion, setasociacion] = useState(""); //Estado para almacenar el nombre de la asociacion
	const [Cuotas, setCuotas] = useState([]); //cuotas pagadas
	const [DojoCuotas, setDojoCuotas] = useState([]); //Dojo de cuotas pagadas
	const [Cursos_ASI, setCursos_ASI] = useState([]); //cursos asistidos y pagados del asociado
	const [Grados, setGrados] = useState([]); //grados del asociado
	const [Titulos, setTitulos] = useState([]); //titulos del asociado
	const [Cursos, setCursos] = useState([]); //cursos del asociado
	const [mostrarTodos, setMostrarTodos] = useState(0); // modal cursos cursados
	const [mostrarGrados, setMostrarGrados] = useState(0); // modal grados cursados
	const [mostarCarnet, setMostarCarnet] = useState(0); // modal carnet
	// const [NRes, setNRes] = useState(0); // Nº de registros con filtro
	// const [NTot, setNTot] = useState(0); // Nº de registros con filtro
	const [Cinturones, setCinturones] = useState(null);
	const [VerIns, setVerIns] = useState(0); // para ver el modal de Inscribirse
	const [asociadoData, setAsociadoData] = useState(null); // para ver el modal de Inscribirse
	const [ListParams, setListParams] = useState({
		num: 15,
		orden: "fecha",
		filtro: "",
		ini: 0,
		nomAso: "",
		idAsoc: 0,
		idReg: 0,
	});

	const colCuotas = [
		"rgb(22 162 147)",
		"rgb(168 0 59)",
		"rgb(224 147 30)",
		"rgb(48 122 180)",
		"rgb(40 158 105)",
		"rgb(183 53 53)",
		"rgb(214 59 113)",
		"rgb(44 184 211)",
		"rgb(48 158 40)",
		"rgb(199 106 0)",
		"rgb(121 18 54)",
		"rgb(106 122 204)",
		"rgb(22 190 77)",
		"rgb(203 63 63)",
		"rgb(168 0 158)",
		"rgb(65 128 199)",
		"rgb(35 208 113)",
		"rgb(228 24 24)",
		"rgb(188 83 132)",
		"rgb(87 64 196)",
	];

	useEffect(() => {
		const cargarCinturones = async () => {
			try {
				const respuesta = await peticion(`/grados/cinturones`);

				if (respuesta?.estado === 0) {
					setCinturones(
						respuesta.res.map((obj) => ({
							...obj,
							fecha: "", // Añade la primera propiedad con el valor deseado
							estado: "", // Añade la segunda propiedad con el valor deseado
						}))
					);
				} else {
					console.error(
						"Error al cargar los cinturones:",
						respuesta?.mensaje ?? "Error desconocido"
					);
				}

				//grado del asociado
				peticion("/grados/gradoactual?idAsoc=" + perfil_id).then((pet) => {
					if (pet.estado === 0) {
						setGrados(pet?.res ?? []);
					}
				});
				//titulo del asociado
				peticion("/grados/tituloactual?idAsoc=" + perfil_id).then((pet) => {
					if (pet.estado === 0) {
						setTitulos(pet?.res ?? []);
					}
				});
			} catch (error) {
				console.error("Error al cargar los cinturones:", error);
			}
		};
		cargarCinturones();
	}, [peticion, perfil_id]);

	//useEffect
	useEffect(() => {
		const ver = async () => {
			//Nombre asociado
			peticion("/asociados/ver?id=" + perfil_id).then((pet) => {
				if (pet.estado === 0) {
					setNombreCompleto(pet.res?.nombre);
					setAvatar(pet.res?.avatar);
					setasociacion(pet.res?.nombreAsoc);
					setNumAcae(pet.res?.numAcae);
					setAsociadoData(pet.res);
				}
			});
			// descargo las asociaciones
			peticion("/asocs/?ini=0&pag=1000&orden=nombre&filtro=").then((pet) => {
				if (pet.estado === 0) setAsociaciones(pet?.res ?? []);
			});
			//descargo los dojos de la asociación que tenga en el perfil
			peticion(
				"/dojos/?ini=0&pag=1000&orden=nombre&filtro=&idAsoc=" + perfil_asoc
			).then((pet) => {
				if (pet.estado === 0) setDojos(pet?.res ?? []);
			});
			//cuotas del asociado
			peticion("/cuotas/listCuotas?idAsoc=" + perfil_id).then((pet) => {
				setDojoCuotas(pet?.Dojo ?? []);
				if (pet.estado === 0) setCuotas(pet?.res ?? []);
			});
			//cursos del asociado
			peticion("/cursos/listCursos?idAsoc=" + perfil_id).then((pet) => {
				if (pet.estado === 0) setCursos_ASI(pet?.res ?? []);
			});
			//grado del asociado
			peticion("/grados/gradoactual?idAsoc=" + perfil_id).then((pet) => {
				if (pet.estado === 0) setGrados(pet?.res ?? []);
			});
		};
		ver();
		// eslint-disable-next-line
	}, [perfil_id, perfil_asoc, peticion]);

	//EFFECT
	useEffect(() => {
		const idRegN = isNaN(parseInt(idReg)) ? 0 : parseInt(idReg); // id del curso en número

		if (ListParams.idReg !== idRegN) {
			return;
		} else {
			listar();
		}

		// eslint-disable-next-line
	}, [ListParams, idReg]);

	// Función para listar cursos
	const listar = async () => {
		const pet = await peticion(
			"/cursos/?" +
				`ini=${ListParams.ini}&` +
				`pag=${ListParams.num}&` +
				`orden=${ListParams.orden}&` +
				`filtro=${ListParams.filtro}`
		);
		if (pet?.estado ?? false)
			// algún error
			return null;
		setCursos(pet.res);
		// setNRes(pet.nRes);
		// setNTot(pet.nTot);
	};

	//Para cambiar el perfil de adm de dojo
	const cambPerfil = async (id, tipo) => {
		if (id === "") return;
		await peticion(`/modPerfil?id=${id}&tipo=${tipo}`);
	};

	//Para cambiar el perfil de adm de dojo
	const cambPermisos = async (permiso, tipo) => {
		if (permiso === "") return;
		const pet = await peticion(`/modPermisos?permiso=${permiso}&tipo=${tipo}`);
		if (pet?.estado ?? 0 === 8) {
			Swal.fire({
				title: loc("Permiso denegado"),
				text: loc("No puedes ponerte este permiso"),
				showConfirmButton: true,
				icon: "error",
			});
			return;
		}
	};

	function obtenerEstado(valor) {
		if (valor < 0) {
			return loc("Pendiente");
		} else if (valor === 0) {
			return loc("Regulado");
		} else if (valor > 0) {
			return loc("Examinado");
		}
		return ""; // Por si acaso se pasa un valor que no cumpla las condiciones
	}

	return (
		<>
			<div className="content-wrapper main-section" id="inicio">
				<header className="backdrop flex-row-item space-between">
					<div className="flex-row-item">
						<InicioIcon className="section-icon" alt={loc("Inicio")} />
						<h1>{loc("Inicio")}</h1>
					</div>
					<MenuToggle Sidebar={Sidebar} setSidebar={setSidebar} />
				</header>

				{Cargando && !VerIns && !mostrarTodos && !mostrarGrados && !mostarCarnet ? (
					<div className="backdrop precarga">
						<div className="loader-wrapper">
							<div className="circle-wrapper">
								<div className="circle-1" />
								<div className="circle-2" />
								<div className="circle-3" />
								<div className="circle-4" />
								<div className="circle-5" />
							</div>
							<AcaeIcon />
						</div>
						<span>{loc("Cargando...")}</span>
					</div>
				) : (
					<div className="content-wrapper">
						<div className="backdrop onboarding">
							<div className="flex-row-item space-between">
								<div className="flex-row-item gap-small">
									<h2>
										¡{loc("Bienvenido") + ", "}
										<Link to={`/${lang}/app/asociados/dojo_0/${perfil_id}/mod`}>
											{nombreCompleto}
										</Link>
										!
									</h2>
									<h2 className="numacae">{numAcae}</h2>
									{asociadoData &&
										<CarnetAcaeBulk
											listaAsociados={[asociadoData]} 
											obtenerTodosLosCarnets={null}
											totalCarnets={1}
											showModal={mostarCarnet}
											setShowModal={setMostarCarnet}
										/>
									}
								</div>
								<AvatarSimple avatar={avatar} />
							</div>
							<ul className="toggle-perms__container">
								<li className={!perfil_admin_orig ? "disabled":""}>
									<input
										type="checkbox"
										className="toggle-perms"
										id="toggle-admin-acae"
										checked={perfil_admin}
										onChange={() => {cambPermisos(!perfil_admin, "admin")}}
									/>
									<label
										htmlFor="toggle-admin-acae"
										className={perfil_admin ? "cta" : "button tertiary"}>
										<AcaeIcon />{loc("Administrador de ACAE")}
									</label>
								</li>
								<li className={!perfil_adm_asoc_orig && !perfil_admin_orig? "disabled":""}>
									<input
										type="checkbox"
										className="toggle-perms"
										id="toggle-admin-asoc"
										checked={perfil_adm_asoc}
										onChange={() => {cambPermisos(!perfil_adm_asoc, "asoc")}}
									/>
									<label
										htmlFor="toggle-admin-asoc"
										className={perfil_adm_asoc ? "cta" : "button tertiary"}>
										<UsersIcon />{loc("Administrador de asociación")}
									</label>
								</li>
								<li className={!perfil_adm_dojo_orig && !perfil_adm_asoc_orig && !perfil_admin_orig ? "disabled":""}>
									<input
										type="checkbox"
										className="toggle-perms"
										id="toggle-admin-dojo"
										checked={perfil_adm_dojo}
										onChange={() => {cambPermisos(!perfil_adm_dojo, "dojo")}}
									/>
									<label
										htmlFor="toggle-admin-dojo"
										className={perfil_adm_dojo ? "cta" : "button tertiary"}>
										<DojoIcon />{loc("Administrador de dojo")}
									</label>
								</li>
							</ul>
							{!perfil_admin && !perfil_adm_asoc && !perfil_adm_dojo ? (
								<>
									<div className="management">
										<div className="horizontal-divider"></div>
										<ul>
											<li className="flex-row-item gap-small">
												<UsersIcon />
												<div className="dojo_container">{asociacion}</div>
											</li>
											<li className="flex-row-item gap-small">
												<DojoIcon />
												{Dojos.map(
													(elem) =>
														elem.idDojo === asociadoData?.cfDojo && (
															<div
																className="dojo_container"
																value={elem.idDojo}
																key={elem.idDojo}>
																{elem.nombre}
															</div>
														)
												)}
											</li>
										</ul>
									</div>
								</>
							) : (
								<>
									<div className="management">
										<div className="horizontal-divider"></div>
										<ul>
											{!perfil_admin && !perfil_adm_asoc ? null : (
												<li className="flex-row-item gap-small">
													<div className="flex-row-item gap-small">
														<UsersIcon />
														<div className="flex-grow go-to">
															<Link
																to={{
																	pathname: `/${lang}/app/dojos/asoc_${perfil_asoc}`,
																}}>
																	<span className="go-to__text grow text-ellipsis">Ver asociación</span>
																	<AcaeArrow className="go-to__icon" />
															</Link>
														</div>
													</div>
													<select
														value={perfil_adm_asoc ? perfil_asoc : ""}
														onChange={(el) => {
															cambPerfil(el.target.value, "asoc");
														}}
														disabled={!perfil_admin}>
														{perfil_adm_asoc ? null : (
															<option value="" key="_">
																{loc("No eres administrador de asociación")}
															</option>
														)}
														{Asociaciones.map((elem) => (
															<option value={elem.idAsoc} key={elem.idAsoc}>
																{elem.nombre} ({elem.zona})
															</option>
														))}
													</select>
												</li>
											)}
											{asociacion && !perfil_admin && !perfil_adm_asoc && (
												<li className="flex-row-item gap-small">
													<div className="flex-row-item gap-small">
														<UsersIcon />
														<div className="flex-grow go-to">
															<Link
																to={{
																	pathname: `/${lang}/app/dojos/asoc_${perfil_asoc}`,
																}}>
																	<span className="go-to__text grow text-ellipsis">Ver asociación</span>
																	<AcaeArrow className="go-to__icon" />
															</Link>
														</div>
													</div>
													<div className="dojo_container">{asociacion}</div>
												</li>
											)}
											<li className="flex-row-item gap-small">
												<div className="flex-row-item gap-small">
													<DojoIcon />
													<div className="flex-grow go-to">
														<Link
															to={{
																pathname: `/${lang}/app/asociados/dojo_${perfil_dojo}`,
															}}>
																<span className="go-to__text grow text-ellipsis">Ver dojo</span>
																<AcaeArrow className="go-to__icon" />
														</Link>
													</div>
												</div>
												<select
													className="dojo_container"
													onChange={(el) => {
														cambPerfil(el.target.value, "dojo");
													}}
													value={perfil_adm_dojo ? perfil_dojo : ""}
													disabled={!perfil_adm_asoc && !perfil_admin}>
													{perfil_adm_dojo ? null : (
														<option value="" key="_">
															{loc(
																"No eres administrador de dojo para esta asociación"
															)}
														</option>
													)}
													{Dojos.map((elem) => (
														<option value={elem.idDojo} key={elem.idDojo}>
															{elem.nombre}
														</option>
													))}
												</select>
											</li>
										</ul>
									</div>
								</>
							)}
						</div>

						<div className="grid">
							<div className="backdrop paid-fees">
								<div className="content-wrapper">
									<h2>{loc("Cuotas")}</h2>
									<ul className="flex-row-item gap-small">
									{Cuotas.length > 0 ? (
										Cuotas.map((el, i) => (
											<li
												className="flex-row-item gap-small"
												key={el}
												style={{ background: colCuotas[el % 20] }}
												title={DojoCuotas && DojoCuotas[i]?.Dojo ? `Pagado en ${DojoCuotas[i].Dojo}` : "Información no disponible"}>
												<span>{el}</span>
												<img src={flor} alt="" />
											</li>
										))
									) : (<div className="dcontent-wrapper"><p className="proximamente">No hay cuotas pagadas</p></div>)}
									</ul>
								</div>
							</div>
							<div className="backdrop grados-overview">
								<div className="content-wrapper">
									<h2>{loc("Grados")}</h2>
									{Grados.filter((el) => el.estado >= 0).length > 0 ? (
										<div className="flex-column">
											{Grados
											.filter((el) => el.estado >= 0) // Filtra elementos con estado >= 0
											.sort((a, b) => b.valor - a.valor) // Ordena de mayor a menor por "valor"
											.slice(0, 1) // Toma los primeros 3 elementos
											.map((el) => (
												<div
													className="flex-column gap-small grados-listing dan grados-lista"
													key={el.idgrado}
												>
													<div className="flex-column gap-small">
														<div className="flex-row-item space-between">
															<div className="cinturon-label">{el?.descripcion}</div>
															<div
															className="grado-color"
															style={{
																background: (() => {
																const cinturonActualIndex = Cinturones?.findIndex(
																	(cinturon) => cinturon.idcinturon === el.idcinturon
																);
																const cinturonActual = Cinturones?.[cinturonActualIndex];

																const colorAnterior =
																	Cinturones?.[cinturonActualIndex - 1]?.color_primario || "transparent";
																const colorSiguiente =
																	Cinturones?.[cinturonActualIndex + 1]?.color_primario || "transparent";

																return cinturonActual?.valor % 10 !== 0
																	? `linear-gradient(90deg, ${colorAnterior} 50%, ${colorSiguiente} 50%)`
																	: cinturonActual?.color_primario || "transparent";
																})(),
															}}
															/>
														</div>
														<div className="flex-row-item space-between">
															<p className="date">{el.fecha}</p>
															<div className="estado-container">{obtenerEstado(el.estado)}</div>
														</div>
													</div>
												</div>
											))}
											{Titulos && Titulos.filter((el) => el.estado >= 0).length > 0 ? (
												<div className="flex-column gap-large">
													<h2>{loc("Títulos")}</h2>
													{Titulos
													.filter((el) => el.estado >= 0) // Filtra elementos con estado >= 0
													.sort((a, b) => b.valor - a.valor) // Ordena de mayor a menor por "valor"
													.slice(0, 1) // Toma 1 elemento
													.map((el) => (
														<div
															className="flex-column gap-small grados-listing dan grados-lista"
															key={el?.idasoc_titulo}
														>
															<div className="flex-column gap-small">
																<div className="flex-row-item space-between">
																	<div className="flex-row-item space-between">
																		<div className="cinturon-label">{el?.descripcion}</div>
																	</div>
																	<div className="titulo-icon black">
																		{ el?.descripcion === "Fukushidoin" ?
																			<FukushidoinIcon />
																		:
																			el?.descripcion === "Shidoin" ?
																			<ShidoinIcon />
																		:
																			el?.descripcion === "Shihan" ?
																			<ShihanIcon />
																		:
																		null
																		}
																	</div>
																</div>
																<div className="flex-row-item space-between">
																	<p className="date">{el?.fecha}</p>
																	<div className="estado-container">{obtenerEstado(el.estado)}</div>
																</div>
															</div>
														</div>
													))}
												</div>
											):(
												<div className="dcontent-wrapper"><p className="proximamente">{loc("No tienes titulos regulados")}</p></div>
											)}
										</div>
									) : (
										<p className="proximamente">{loc("No tienes grados regulados")}</p>
									)}
									<button
										className="cta"
										onClick={() => setMostrarGrados(perfil_id)}>
										{loc("Gestionar grados")}
									</button>
								</div>
							</div>
							<div className="backdrop cursos-overview">
								<div className="content-wrapper">
									<div className="flex-row-item gap-small">
										<h2>{loc("Mis cursos")}</h2>
										<span className="estado-container">
											{Cursos_ASI.length}
										</span>
									</div>
									<ul className="list-view-curso grid">
										{Cursos_ASI.length > 0 ? (
											<>
												{Cursos_ASI.slice(0, 4).map((el) => (
													<li key={el.idcurso} className="cursos-lista">
														<div className="flex-row-item gap-small">
															<AcaeIcon />
															<p className="location">{el?.ciudad}</p>
														</div>
														<p className="date">
															{new Intl.DateTimeFormat(
																lang + "-" + lang.toUpperCase(),
																{
																	day: "numeric",
																}
															)
																.format(new Date(el.fechaini))
																.toUpperCase()}
															{" — "}
															{new Intl.DateTimeFormat(
																lang + "-" + lang.toUpperCase(),
																{
																	day: "numeric",
																	month: "short",
																	year: "numeric",
																}
															)
																.format(new Date(el.fechafin))
																.toUpperCase()}
														</p>
													</li>
												))}
											</>
										) : (
											<p className="proximamente">{loc("No tienes cursos")}</p>
										)}
									</ul>
									{Cursos_ASI.length > 0 && (
										<button
											className="button ver-mas__lite"
											onClick={() => setMostrarTodos(perfil_id)}>
											{loc("Ver todos")}
										</button>
									)}
								</div>
							</div>
						</div>
						<div className="backdrop cursos-disponibles">
							<div className="content-wrapper">
								<h2>{loc("Próximos cursos")}</h2>
								<ul className="quick-view-curso grid">
									{Cursos.length > 0 ? (
										Cursos.map((curso) =>
											new Date(curso.fechaFin) >= new Date() &&
											curso.cfValAdmi ? (
												<li
													className="quick-view-curso__item"
													key={curso.idCurso}>
													<div className="flex-column gap-small">
														<h4>{curso.titulo}</h4>
														<div className="flex-row-item gap-small">
															<p className="date">
																{new Intl.DateTimeFormat(
																	lang + "-" + lang.toUpperCase(),
																	{
																		day: "numeric",
																		month: "long",
																	}
																)
																	.format(new Date(curso.fechaIni))
																	.toUpperCase()}
															</p>
															{new Date(curso.fechaIni) < new Date() ? (
																<div className="estado-curso">
																	{loc("En curso")}
																</div>
															) : null}
														</div>
														<div className="flex-row-item gap-small">
															<CursoIcon />
															<p className="location">{curso.ciudad}</p>
														</div>
													</div>
													<button
														className="cta"
														onClick={() => {
															setVerIns(curso.idCurso);
														}}>
														{loc("Apúntate")}
													</button>
												</li>
											) : null
										)
									) : (
										<p className="proximamente">
											{loc("No hay cursos disponibles")}
										</p>
									)}
								</ul>
							</div>
						</div>

						<div className="backdrop tribunales-disponibles">
							<div className="content-wrapper">
								<h2>{loc("Tribunales")}</h2>
								<p className="proximamente">{loc("Próximamente disponible")}</p>
							</div>
						</div>

						<InscripcionMod
							show={VerIns}
							ListParams={ListParams}
							setListParams={setListParams}
							onHide={() => setVerIns(0)}
							idAsociado={perfil_id}
						/>
					</div>
				)}
			</div>
			<MisCursosMod show={mostrarTodos} onHide={() => setMostrarTodos(0)} />
			<MisGradosMod
				show={mostrarGrados}
				onHide={() => setMostrarGrados(0)}
				asociadoData={asociadoData}
				setGrados={setGrados}
			/>
		</>
	);
};
